import React, { useEffect, useState } from 'react';
import ButtonTheme from '../components/Button';
import {
  ChakraProvider,
  theme,
  Link,
  Button,
  Image,
  HStack,
  Card,
  CardBody,
  Stack,
  SimpleGrid,
  defineStyle,
  defineStyleConfig,
  Text,
  Box,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive';
// import { Carousel } from 'react-carousel-minimal';
import 'react-visual-grid/dist/react-visual-grid.css';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { db, auth } from '../firebase-config';
import { useNavigate } from 'react-router';
import ReactPlayer from 'react-player';
function Home() {
  const [venuesData, setVenuesData] = useState([]);
  const [eventsData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navigate = useNavigate();
  function missingProfile() {
    navigate('/createuser');
  }

  function goToLogin() {
    navigate('/login');
  }

  function goToRedeem() {
    navigate('/redeem');
  }

  function goToProfile() {
    navigate('/profile');
  }
  
  function goToCreateUser() {
    navigate('/createuser');
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const venuesArr = [];
        const eventsArr = [];
        const venuesRef = collection(db, 'featuredVenues');
        const venueDocsRef = await getDocs(venuesRef);
        const eventsRef = collection(db, 'featuredEvents');
        const eventsDocsRef = await getDocs(eventsRef);
        // setting the venues pages
        venueDocsRef.forEach(doc => {
          if (doc.data()['showVenue']) {
            venuesArr.push({
              imgurl: doc.data().squareCoverImage,
              path: doc.data()['id'],
              comingSoon: doc.data()['comingSoon'],
              comingSoonImg: doc.data()['blurredImage'],
            });
          }
        });
        // setting the events pages
        eventsDocsRef.forEach(doc => {
          if (doc.data()['showEvent']) {
            eventsArr.push({
              imgurl: doc.data().eventFlyerPicture,
              path: doc.data()['id'],
              eventTime: doc.data()['eventTime'], // Add this line to capture eventTime
            });
          }
        });

        // Sort eventsArr by eventTime (earliest events first)
        eventsArr.sort((a, b) => b.eventTime - a.eventTime);
        if (auth.currentUser == null) {
          setLoggedIn(false);
          // console.log('nouser');
          // console.log(auth.currentUser);
        } else {
          setLoggedIn(true);
          // console.log('user');
          // console.log(auth.currentUser);
          const userUID = auth.currentUser.uid;
          const snap = await getDoc(doc(db, 'Users', userUID));
          if (snap.data()['birthdate'] === '' && snap.data()['firstName'] === '' && snap.data()['lastName'] === '') {
            // console.log('missing profile');
            goToCreateUser();
          }
        }
        setEventData(eventsArr);
        setVenuesData(venuesArr);
      } catch (error) {
        console.error('Error fetching data from the database:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  };

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  // function signOutFunction() {
  //   signOut(auth);
  //   window.location.reload(false);
  // }
  document.body.style.background = '#141416';

  return (
    <ChakraProvider theme={ButtonTheme}>
      {(isMobile || isTablet) && (
        <div
          style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}
        >
          {loading ? (
            <p style={{ color: 'white' }}>Loading...</p>
          ) : (
            <div style={{ padding: '0 0px' }}>
              <div
                className="video-container"
                style={{
                  position: 'relative',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <Image src="/landing.gif" width="100%" height="auto"></Image>
                <div
                  style={{
                    position: 'absolute',
                    top: 40,
                    right: 20,
                    transform: 'translate(0%, -50%)',
                    zIndex: 1,
                  }}
                >
                  {loggedIn ? (
                    <Button variant="solid" onClick={goToProfile}>
                      Profile
                    </Button>
                  ) : (
                    <>
                      <Button variant="solid" onClick={goToLogin}>
                        Login or Signup
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div style={{ backgroundColor: '#00091b' }}>
                <VStack justifyContent="center">
                  {' '}
                  {/* Added justifyContent prop to center the content */}
                  <Stack textAlign="center">
                    <Box width="300px">
                      <Text
                        fontSize={45}
                        css={{
                          background:
                            'linear-gradient(to right, #008cff, #ff008c 80%)',
                          '-webkit-background-clip': 'text',
                          '-webkit-text-fill-color': 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Nightlife.
                      </Text>
                      <Text
                        fontSize={45}
                        css={{
                          background:
                            'linear-gradient(to right, #008cff, #ff008c 80%)',
                          '-webkit-background-clip': 'text',
                          '-webkit-text-fill-color': 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Reimagined.
                      </Text>
                      <Text
                        fontSize={20}
                        textColor="white"
                        css={{
                          background:
                            'linear-gradient(to right, #008cff, #ff008c 80%)',
                          '-webkit-background-clip': 'text',
                          '-webkit-text-fill-color': 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        From fast passes to the hottest clubs to exclusive VIP
                        experiences, Wave is your passport to a better night
                        out.
                      </Text>
                    </Box>
                  </Stack>
                  <Image
                    src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fhomepage_img_1.png?alt=media&token=3d75cc4c-05b6-47ae-b34e-59de937f901e"
                    width="600px"
                    height="auto"
                  />
                </VStack>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  position="relative"
                  // width="100%"
                  // height="750px"
                  // overflow="hidden"
                  // margin="0"
                  // padding="0"
                >
                  <Image
                    style={{ height: 780 }}
                    src="/gradientbar.png"
                    width="100%"
                  ></Image>
                  <Box
                    position="absolute"
                    top="40%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="90%"
                  >
                    <Stack>
                      <div style={{ height: 170 }} />
                      <Text fontSize={25} textColor="dodgerblue" as="b">
                        EXPLORE UNFORGETTABLE EVENTS.
                      </Text>
                      <Text fontSize={25} textColor="white" as="b">
                        TRUSTED BY THE BEST.
                      </Text>
                      <div style={{ height: 15 }} />
                      <VStack justifyContent="center" align="center">
                        <Spacer />
                        <Box>
                          <Image
                            style={{ height: 100 }}
                            src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2FBarstool%20Nashville%20Logo%201.png?alt=media&token=0cdf7cf5-d7a1-47ff-aa0c-63bd4abe07cc"
                          />
                        </Box>
                        <Spacer />
                        <Box>
                          <Image
                            style={{ height: 100 }}
                            src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2Faffinity-entertainment-nashville-troubadour-golf-club.png.png?alt=media&token=6ba2adc2-e491-4e76-9cb3-701ec4aefa27"
                          />
                        </Box>
                        <Spacer />
                        <Box>
                          <Image
                            style={{ height: 100 }}
                            src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2FVirgin%20Logo%20White%201.png?alt=media&token=81407fdb-8c7c-447a-b878-52c3294cdd37"
                          />
                        </Box>
                        <Spacer />
                        <Box>
                          <Image
                            style={{ height: 100 }}
                            src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2FAffinity_Analog-at-Hutton-Hotel.png.png?alt=media&token=e7f4cdb5-c880-4fa2-aa0b-ffea4ed9db3c"
                          />
                        </Box>
                        <Spacer />
                        <Box>
                          <Image
                            style={{ height: 100 }}
                            src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2Fimage%201.png?alt=media&token=8aa24b36-cdab-4b65-83b3-f8d594eba16b"
                          />
                        </Box>
                        <Spacer />
                      </VStack>
                    </Stack>
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  paddingTop: '20px',
                  paddingRight: '40px',
                  paddingLeft: '40px',
                  paddingBottom: '10px',
                }}
              >
                <Text
                  fontSize={40}
                  textAlign="left"
                  textColor="white"
                  css={{
                    background:
                      'linear-gradient(to right, #008cff, #ff008c 30%)', // Replace the color codes with your desired gradient colors
                    '-webkit-background-clip': 'text', // This is necessary for the gradient to apply to the text
                    '-webkit-text-fill-color': 'transparent', // This is necessary to make the text transparent and let the gradient show through
                    fontWeight: 'bold',
                  }}
                >
                  VIP Wave Passes
                </Text>
                <Text
                  fontSize={25}
                  textAlign="left"
                  textColor="white"
                  css={{
                    fontWeight: 'bold',
                  }}
                >
                  Skip the wait at top venues with our VIP fast pass service.
                </Text>
              </div>
              <div
                style={{
                  paddingTop: '10px',
                  paddingRight: '30px',
                  paddingLeft: '30px',
                }}
              >
                <SimpleGrid columns={1} spacing={5}>
                  {venuesData.map(item => (
                    <div key={item.path}>
                      {item.comingSoon ? (
                        // Render content for items with comingSoon=true

                        <Image
                          src={item.comingSoonImg}
                          style={{ borderRadius: 15 }}
                        />
                      ) : (
                        // Render content for items with comingSoon=false
                        <a href={'/venues/' + item.path}>
                          <Image
                            src={item.imgurl}
                            style={{ borderRadius: 15 }}
                          />
                        </a>
                      )}
                    </div>
                  ))}
                </SimpleGrid>
              </div>
              <div
                style={{
                  paddingTop: '20px',
                  paddingRight: '40px',
                  paddingLeft: '40px',
                  paddingBottom: '10px',
                }}
              >
                <Text
                  fontSize={40}
                  textAlign="left"
                  textColor="white"
                  css={{
                    background:
                      'linear-gradient(to right, #008cff, #ff008c 40%)', // Replace the color codes with your desired gradient colors
                    '-webkit-background-clip': 'text', // This is necessary for the gradient to apply to the text
                    '-webkit-text-fill-color': 'transparent', // This is necessary to make the text transparent and let the gradient show through
                    fontWeight: 'bold',
                  }}
                >
                  Events by Affinity Entertainment
                </Text>
                <Text
                  fontSize={25}
                  textAlign="left"
                  textColor="white"
                  css={{
                    fontWeight: 'bold',
                  }}
                >
                  Enjoy access to a curated selection of exceptional events.
                </Text>
              </div>
              <div
                style={{
                  paddingTop: '10px',
                  paddingRight: '30px',
                  paddingLeft: '30px',
                }}
              >
                <SimpleGrid columns={1} spacing={5}>
                  {eventsData.map(item => (
                    <div>
                      <a href={'/events/' + item.path}>
                        <Image src={item.imgurl} style={{ borderRadius: 15 }} />
                      </a>
                    </div>
                  ))}
                </SimpleGrid>
              </div>
            </div>
          )}
        </div>
      )}
      {isDesktop && (
        <div
          style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}
        >
          {loading ? (
            <p style={{ color: 'white' }}>Loading...</p>
          ) : (
            <div style={{ padding: '0 0px' }}>
              <div
                className="video-container"
                style={{
                  position: 'relative',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <ReactPlayer
                  url="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2FWaveRecap_Edit%2C1.mp4?alt=media&token=f6cc0b1f-9c12-4206-978a-a00826c1d1de"
                  playing={true}
                  controls={false}
                  loop={true}
                  muted={true}
                  width="100%"
                  height="auto"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%', // Adjust the container width to make the image larger
                    height: '100%', // Adjust the container height to make the image larger
                  }}
                >
                  {/* <img
                    style={{ width: '100%', height: 'auto' }} // Set width and height to '100%'
                    src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                    alt="Logo"
                  /> */}
                </div>
                <div
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black color with 70% opacity
                    height: '120px', // Set your desired height
                    width: '100%',
                    position: 'fixed', // Fixed position to stay at the top of the page
                    top: 0,
                    zIndex: 1000, // Higher z-index to make sure it appears above other elements
                  }}
                >
                  <div style={{ height: 150 }}></div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '60px',
                      left: '200px',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1001,
                    }}
                  >
                    <a href="/">
                      <img
                        style={{ width: 300, height: 100 }}
                        src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                      />
                    </a>
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      top: '60px',
                      right: '50px',
                      transform: 'translate(0%, -50%)',
                      zIndex: 1001,
                    }}
                  >
                    {loggedIn ? (
                      <Button variant="solid" onClick={goToProfile}>
                        Profile
                      </Button>
                    ) : (
                      <>
                        <Button variant="solid" onClick={goToLogin}>
                          Login or Signup
                        </Button>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '60px',
                      right: '160px',
                      transform: 'translate(0%, -50%)',
                      zIndex: 1001,
                    }}
                  >
                    {loggedIn ? (
                     <Button variant="solid" onClick={goToRedeem}>
                     Redeem
                   </Button>
                    ) : (
                      <>
                        
                      </>
                    )}
                    
                  </div>
                </div>
              </div>

              <div style={{ backgroundColor: '#00091b' }}>
                <HStack justifyContent="center">
                  {' '}
                  {/* Added justifyContent prop to center the content */}
                  <Stack textAlign="center">
                    <Box width="500px">
                      <Text
                        fontSize={60}
                        css={{
                          background:
                            'linear-gradient(to right, #008cff, #ff008c 80%)',
                          '-webkit-background-clip': 'text',
                          '-webkit-text-fill-color': 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Nightlife.
                      </Text>
                      <Text
                        fontSize={60}
                        css={{
                          background:
                            'linear-gradient(to right, #008cff, #ff008c 80%)',
                          '-webkit-background-clip': 'text',
                          '-webkit-text-fill-color': 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Reimagined.
                      </Text>
                      <Text
                        fontSize={20}
                        textColor="white"
                        css={{
                          background:
                            'linear-gradient(to right, #008cff, #ff008c 80%)',
                          '-webkit-background-clip': 'text',
                          '-webkit-text-fill-color': 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        From fast passes to the hottest clubs to exclusive VIP
                        experiences, Wave is your passport to a better night
                        out.
                      </Text>
                    </Box>
                  </Stack>
                  <Image
                    src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fhomepage_img_1.png?alt=media&token=3d75cc4c-05b6-47ae-b34e-59de937f901e"
                    width="600px"
                    height="auto"
                  />
                </HStack>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  position="relative"
                  width="100%"
                  height="350px"
                  overflow="hidden"
                  margin="0"
                  padding="0"
                >
                  <Image src="/gradientbar.png" width="100%"></Image>
                  <Box
                    position="absolute"
                    top="40%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="100%"
                  >
                    <Stack>
                      <div style={{ height: 15 }} />
                      <Text fontSize={30} textColor="dodgerblue" as="b">
                        EXPLORE UNFORGETTABLE EVENTS.
                      </Text>
                      <Text fontSize={30} textColor="white" as="b">
                        TRUSTED BY THE BEST.
                      </Text>
                      <div style={{ height: 15 }} />
                      <HStack
                        spacing={4}
                        justifyContent="center"
                        align="center"
                      >
                        <Spacer />
                        <Box flex="2" textAlign="center">
                          <Image src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2FBarstool%20Nashville%20Logo%201.png?alt=media&token=0cdf7cf5-d7a1-47ff-aa0c-63bd4abe07cc" />
                        </Box>
                        <Spacer />
                        <Box flex="1" textAlign="center">
                          <Image src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2Faffinity-entertainment-nashville-troubadour-golf-club.png.png?alt=media&token=6ba2adc2-e491-4e76-9cb3-701ec4aefa27" />
                        </Box>
                        <Spacer />
                        <Box flex="2" textAlign="center">
                          <Image src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2FVirgin%20Logo%20White%201.png?alt=media&token=81407fdb-8c7c-447a-b878-52c3294cdd37" />
                        </Box>
                        <Spacer />
                        <Box flex="2" textAlign="center">
                          <Image src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2FAffinity_Analog-at-Hutton-Hotel.png.png?alt=media&token=e7f4cdb5-c880-4fa2-aa0b-ffea4ed9db3c" />
                        </Box>
                        <Spacer />
                        <Box flex="1" textAlign="center">
                          <Image src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fnew%20logos%2Fimage%201.png?alt=media&token=8aa24b36-cdab-4b65-83b3-f8d594eba16b" />
                        </Box>
                        <Spacer />
                      </HStack>
                    </Stack>
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  paddingTop: '20px',
                  paddingRight: '25px',
                  paddingLeft: '60px',
                  paddingBottom: '15px',
                }}
              >
                <Text
                  fontSize={60}
                  textAlign="left"
                  textColor="white"
                  css={{
                    background:
                      'linear-gradient(to right, #008cff, #ff008c 30%)', // Replace the color codes with your desired gradient colors
                    '-webkit-background-clip': 'text', // This is necessary for the gradient to apply to the text
                    '-webkit-text-fill-color': 'transparent', // This is necessary to make the text transparent and let the gradient show through
                    fontWeight: 'bold',
                  }}
                >
                  VIP Wave Passes
                </Text>
                <Text
                  fontSize={40}
                  textAlign="left"
                  textColor="white"
                  css={{
                    fontWeight: 'bold',
                  }}
                >
                  Skip the wait at top venues with our VIP fast pass service.
                </Text>
              </div>
              <div
                style={{
                  paddingTop: '10px',
                  paddingRight: '60px',
                  paddingLeft: '60px',
                }}
              >
                <SimpleGrid columns={4} spacing={10}>
                  {venuesData.map(item => (
                    <div key={item.path}>
                      {item.comingSoon ? (
                        // Render content for items with comingSoon=true

                        <Image
                          src={item.comingSoonImg}
                          style={{ borderRadius: 15 }}
                        />
                      ) : (
                        // Render content for items with comingSoon=false
                        <a href={'/venues/' + item.path}>
                          <Image
                            src={item.imgurl}
                            style={{ borderRadius: 15 }}
                          />
                        </a>
                      )}
                    </div>
                  ))}
                </SimpleGrid>
              </div>
              <div
                style={{
                  paddingTop: '20px',
                  paddingLeft: '60px',
                  paddingBottom: '15px',
                }}
              >
                <Text
                  fontSize={60}
                  textAlign="left"
                  textColor="white"
                  css={{
                    background:
                      'linear-gradient(to right, #008cff, #ff008c 40%)', // Replace the color codes with your desired gradient colors
                    '-webkit-background-clip': 'text', // This is necessary for the gradient to apply to the text
                    '-webkit-text-fill-color': 'transparent', // This is necessary to make the text transparent and let the gradient show through
                    fontWeight: 'bold',
                  }}
                >
                  Events by Affinity Entertainment
                </Text>
                <Text
                  fontSize={40}
                  textAlign="left"
                  textColor="white"
                  css={{
                    fontWeight: 'bold',
                  }}
                >
                  Enjoy access to a curated selection of exceptional events.
                </Text>
              </div>
              <div
                style={{
                  paddingTop: '10px',
                  paddingRight: '60px',
                  paddingLeft: '60px',
                }}
              >
                <SimpleGrid columns={4} spacing={10}>
                  {eventsData.map(item => (
                    <div>
                      <a href={'/events/' + item.path}>
                        <Image src={item.imgurl} style={{ borderRadius: 15 }} />
                      </a>
                    </div>
                  ))}
                </SimpleGrid>
              </div>
            </div>
          )}
        </div>
      )}
    </ChakraProvider>
  );
}

export default Home;
