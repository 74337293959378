import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  ChakraProvider,
  AspectRatio,
  theme,
  Grid,
  Text,
  IconButton,
  Image,
  Link,
  Button,
  Divider,
  Center,
  Flex,
  HStack,
  VStack,
  Spacer,
  Box,
  SimpleGrid,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaBullhorn, FaInstagram, FaTag } from 'react-icons/fa';
import {
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db, auth } from '../firebase-config';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import ButtonTheme from '../components/Button';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaApple } from 'react-icons/fa';
import { AiFillAndroid } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive';
import { MyPaymentForm } from '../components/CheckoutDrawer';
function stripePromise() {
  return loadStripe(process.env.REACT_APP_STRIPE_KEY);
}

function EventPage() {
  const {
    isOpen: isDiscountOpen,
    onOpen: onDiscountOpen,
    onClose: onDiscountClose,
  } = useDisclosure();
  const {
    isOpen: isInfoOpen,
    onOpen: onInfoOpen,
    onClose: onInfoClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [inThePast, setinThePast] = useState(false);
  const location = useLocation();
  const firstField = React.useRef();
  const pathArray = location.pathname.split('/');
  const [eventTime, setEventTime] = useState(null);
  const [eventEndTime, setEventEndTime] = useState(null);
  const [doorsOpen, setDoorsOpen] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [discountCode, setDiscountCode] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [intentPrice, setIntentPrice] = useState(0);
  const [waveFee, setWaveFee] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [passIndex, setPassIndex] = useState(-1);
  const [buyNowButtonLoading, setBuyNowButtonLoading] = useState(false);
  const [discountButtonLoading, setDiscountButtonLoading] = useState(false);
  const [codeApplied, setCodeApplied] = useState(false);
  const [ticketCodes, setTicketCodes] = useState('');
  const [usedDiscountCode, setUsedDiscountCode] = useState('');
  const [loadingCodes, setCodesLoading] = useState(true);
  const toast = useToast();
  const [taxRate, setTaxRate] = useState(0.0975);

  const TAX_RATE = 0.0975;
  const WAVE_FEE_RATE = 0.15;

  let navigate = useNavigate();
  const RouteChange = () => {
    let path = '/not-found';
    navigate(path);
  };

  const RedeemRoute = () => {
    let path = '/redeem';
    navigate(path);
  };
  const [ticketCount, setTicketCount] = useState(1); // Initial count

  const increment = () => {
    setTicketCount(ticketCount + 1);
  };

  const decrement = () => {
    if (ticketCount > 1) {
      setTicketCount(ticketCount - 1);
    }
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  function havePass(passes, passName) {
    let i = 0;
    for (i = 0; i < passes.length; i++) {
      if (passes[i].passName === passName) {
        return true;
      }
    }
    return false;
  }

  const handleDiscountCode = async () => {
    setDiscountButtonLoading(true);
    // console.log('Discount code:', discountCode);
    if (data.discountCodes) {
      let matched = false;
      // loop through the discount codes and check if the input matches any
      for (let i = 0; i < data.discountCodes.length; i++) {
        if (discountCode === data.discountCodes[i].code) {
          // if it matches, then check if it's a percentage or a fixed amount
          // loop through prices array, only change the price of data.discountCodes[i].ticketType === data.prices[i].displayType
          for (let j = 0; j < data.prices.length; j++) {
            if (
              data.discountCodes[i].ticketType === data.prices[j].displayType
            ) {
              matched = true;
              if (data.discountCodes[i].type === 'percentage') {
                // if it's a percentage, then calculate the new price and set price in price array
                // const newPrice = intentPrice * data.discountCodes[i].discount;
                // reset data here?
                data.prices[j].price =
                  data.prices[j].price * data.discountCodes[i].discount;
                // setIntentPrice(newPrice);
              } else if (data.discountCodes[i].type === 'fixed') {
                // if it's a fixed amount, then subtract the amount from the price and set price in price array
                data.prices[j].price =
                  data.prices[j].price - data.discountCodes[i].discount;
              }
            }
          }
        }
      }
      if (!matched) {
        // toast to say no code found
        toast({
          title: 'Invalid code',
          description: 'The code you entered is not valid.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else {
        // toast to say code applied
        toast({
          title: 'Code applied',
          description: 'The code you entered has been applied.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
        setCodeApplied(true);
        setUsedDiscountCode(discountCode);
      }
      setDiscountButtonLoading(false);
      onDiscountClose();
      setDiscountCode('');
      return;
    }
  };

  const linkToAppStore = () => {
    const url = 'https://apps.apple.com/us/app/wave-vip/id6447489243';
    window.open(url, '_blank');
  };

  const linkToMobileAppStore = () => {
    const url = 'https://apps.apple.com/us/app/wave-vip/id6447489243';
    window.location.href = url; // Redirect to the link
  };

  async function taxRateSet(noTax) {
    if (noTax) {
      setTaxRate(0);
    } else {
      setTaxRate(TAX_RATE);
    }
  }

  function goToCreateUser() {
    navigate('/createuser');
  }

  const drawerHook = async (price, index, numTickets) => {
    if (auth.currentUser == null) {
      goToLogin();
    } else {
      const docRef = doc(db, 'Users', auth.currentUser.uid);
      const snap = await getDoc(docRef);
      let tempPasses = snap.data()['passes'];

      // here, check if the pass is sold out
      const eventsQuery = query(
        collection(db, 'featuredEvents'),
        where('id', '==', pathArray[2])
      );
      const querySnapshot = await getDocs(eventsQuery);
      const eventDocSnap = querySnapshot.docs[0];
      const eventDocData = eventDocSnap.data();
      const prices = eventDocData.prices;
      const priceObj = prices[index];
      taxRateSet(priceObj.noTax);
      let tempTax = TAX_RATE;
      if (priceObj.noTax) {
        tempTax = 0;
      }

      if (
        havePass(
          tempPasses,
          `${data.passName}: ${data.prices[index].displayType}`
        )
      ) {
        toast({
          title: 'You already have this pass!',
          description: 'Download the app to view your passes and tickets.',
          status: 'info',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
        closeDrawer();
        return;
      } else {
        // console.log(priceObj);

        if (numTickets === 1) {
          // set button to loading state
          setBuyNowButtonLoading(true);

          if (priceObj.soldOut) {
            toast({
              title: 'Error: This pass is sold out.',
              description: 'Download the app to view your passes and tickets.',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
            closeDrawer();
            return;
          }

          const newTicketsPurchased = priceObj.ticketsPurchased + 1;
          prices[index].ticketsPurchased = newTicketsPurchased;

          // If the new amount of ticketsPurchased reaches the ticketLimit, set soldOut to true
          if (newTicketsPurchased >= priceObj.ticketLimit) {
            prices[index].soldOut = true;
          }

          // once checked if it's sold out, then move into purchase options (free or not)
          if (price > 0) {
            fetch(
              // 'https://us-central1-wave-dynamics.cloudfunctions.net/stripePaymentIntentRequestTestMode',
              'https://us-central1-wave-dynamics.cloudfunctions.net/stripePaymentIntentRequest',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: auth.currentUser.email,
                  amount: price * (1 + WAVE_FEE_RATE) * (1 + tempTax),
                }),
              }
            )
              .then(response => response.json())
              .then(data => {
                setClientSecret(data.paymentIntent);
                setPassIndex(index);
                setIntentPrice(price * (1 + WAVE_FEE_RATE) * (1 + tempTax));
                setWaveFee(price * WAVE_FEE_RATE);
                setTaxAmt(price * (1 + WAVE_FEE_RATE) * tempTax);
                onOpen();
              })
              .catch(error => {
                console.error('Error fetching client secret:', error);
              });
          } else {
            // if free
            tempPasses.push({
              passName: `${data.passName}: ${data.prices[index].displayType}`,
              datePurchased: new Date().toISOString(),
              isActive: true,
              eventTime: data.eventTime,
              eventEndTime: data.eventEndTime,
              venueId: data.venueId,
              passImage: data.eventFlyerPicture,
              ticketType: 'event',
              price: 0,
              discountCode: usedDiscountCode,
              numTickets: 1,
              taxAmount: 0,
              waveFeeAmount: 0,
              id: pathArray[2],
            });
            // Update the featuredEvents document with the new ticketsPurchased and soldOut values
            const eventDocRef = eventDocSnap.ref;
            await updateDoc(eventDocRef, {
              prices: prices, // This contains the modified prices array
            });

            await updateDoc(docRef, {
              updateRequired: true,
              passes: tempPasses,
            });

            toast({
              title: 'Purchase was successful!',
              description: 'Download the app to view your passes and tickets.',
              status: 'success',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
            closeDrawer();

            // here - redirect to profile page
            const timer = setTimeout(() => {
              // Perform the action you want after the sleep here
              goToProfile();
            }, 5000);
          }
        } else {
          // multiple tickets being purchased
          // same payment flow
          // instead of previous option, we generate codes this time with an API call
          // one ticket gets added to user - price field is all the tickets cost combined, redeemed are price = 0
          setBuyNowButtonLoading(true);

          // if there aren't enough tickets to purchase the amount requested
          if (priceObj.ticketLimit - priceObj.ticketsPurchased < numTickets) {
            toast({
              title: 'Error: Not enough tickets available.',
              description:
                'Reduce the number of tickets or select a different pass type.',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
            setBuyNowButtonLoading(false);
            return;
          }

          fetch(
            // 'https://us-central1-wave-dynamics.cloudfunctions.net/stripePaymentIntentRequestTestMode',
            'https://us-central1-wave-dynamics.cloudfunctions.net/stripePaymentIntentRequest',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: auth.currentUser.email,
                amount: price * (1 + WAVE_FEE_RATE) * (1 + tempTax),
              }),
            }
          )
            .then(response => response.json())
            .then(res => {
              setClientSecret(res.paymentIntent);
              setPassIndex(index);
              setIntentPrice(price * (1 + WAVE_FEE_RATE) * (1 + tempTax));
              setWaveFee(price * WAVE_FEE_RATE);
              setTaxAmt(price * (1 + WAVE_FEE_RATE) * tempTax);
              onOpen();
            })
            .catch(error => {
              console.error('Error fetching client secret:', error);
            });

          // const functions = getFunctions();
          // const verifyCode = httpsCallable(functions, 'verifyCode');
          // verifyCode({ code: "5xy6n3" })
          //   .then((result) => {
          //     // Read result of the Cloud Function.
          //     /** @type {any} */
          //     const data = result.data;
          //     console.log(data);
          //   });
        }
      }
    }
  };

  const handleDirections = address => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      address
    )}`;
    window.open(url, '_blank');
  };

  const goHome = () => {
    let path = '/';
    navigate(path);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'featuredEvents'),
          where('id', '==', pathArray[2])
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
          RouteChange();
        } else {
          querySnapshot.forEach(doc => {
            setData(doc.data());
            // console.log(doc.data());
          });
        }
        if (auth.currentUser == null) {
          setLoggedIn(false);
        } else {
          setLoggedIn(true);
          const userUID = auth.currentUser.uid;
          const snap = await getDoc(doc(db, 'Users', userUID));
          if (
            snap.data()['birthdate'] === '' &&
            snap.data()['firstName'] === '' &&
            snap.data()['lastName'] === ''
          ) {
            goToCreateUser();
          }
        }
      } catch (error) {
        console.error('Error fetching data from the database:', error);
      } finally {
        setLoading(false);
      }
    };

    document.body.style.background = '#141416';
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     // Find the meta tags by their property name (not by ID)
  //     const titleMeta = document.querySelector('meta[name="og:title"]');
  //     const imageMeta = document.querySelector('meta[name="og:image"]');

  //     // Set the content attribute of the meta tags
  //     if (titleMeta) titleMeta.content = data.eventName;
  //     if (imageMeta) imageMeta.content = data.eventFlyerPicture;
  //   }
  // }, [data]);

  useEffect(() => {
    if (
      data.eventTime &&
      data.eventTime.seconds &&
      data.eventEndTime &&
      data.eventEndTime.seconds
    ) {
      const eventTimeSeconds = data.eventTime.seconds;
      const date = new Date(0); // Create a new date object with timestamp 0 (epoch)
      date.setUTCSeconds(eventTimeSeconds);
      setEventTime(date);

      const eventEndTimeSeconds = data.eventEndTime.seconds;
      const eventEndTimedate = new Date(0); // Create a new date object with timestamp 0 (epoch)
      eventEndTimedate.setUTCSeconds(eventEndTimeSeconds);
      setEventEndTime(eventEndTimedate);

      const doorsOpenSeconds = data.doorsOpen.seconds;
      const doorsOpenDate = new Date(0); // Create a new date object with timestamp 0 (epoch)
      doorsOpenDate.setUTCSeconds(doorsOpenSeconds);
      setDoorsOpen(doorsOpenDate);
    }
  }, [data]);

  useEffect(() => {
    if (eventEndTime && moment(eventEndTime) < moment()) {
      setinThePast(true);
    }
  }, [eventEndTime]);

  function to12HrTime(time) {
    let hrs = time.slice(0, 2);
    if (hrs > 12) {
      return hrs - 12 + ':' + time.slice(3, 5) + ' PM';
    } else if (hrs === '12') {
      return '12:00 PM';
    } else if (hrs === '00') {
      return '12:00 AM';
    } else {
      return hrs + ':' + time.slice(3, 5) + ' AM';
    }
  }

  function goToLogin() {
    navigate(`/login?redirect=${pathArray[2]}`);
  }

  function goToProfile() {
    navigate('/profile');
  }

  function closeDrawer() {
    onClose();
    setBuyNowButtonLoading(false);
  }

  return (
    <>
      <Helmet>
        <title>{data.eventName}</title>
        <meta property="og:title" content={data.eventName} />
        <meta property="og:image" content={data.eventFlyerPicture} />
      </Helmet>
      <ChakraProvider theme={ButtonTheme}>
        {(isTablet || isMobile) && (
          <div>
            <img
              src={data.eventFlyerPicture}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -2,
                filter: 'blur(20px) brightness(80%)',
                objectFit: 'cover',
              }}
              alt="Blurry Image"
              className="blur-image"
            />
            <VStack>
              <div style={{ height: 90 }}></div>
              <div
                style={{
                  position: 'absolute',
                  top: 25,
                  left: 20,
                  // transform: 'translate(-50%, -50%)',
                  zIndex: 2,
                  width: '90%',
                }}
              >
                <a href="/">
                  <img
                    style={{ width: 160, height: 50, zIndex: 2 }}
                    src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                  />
                </a>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 50,
                  right: 20,
                  transform: 'translate(0%, -50%)',
                  zIndex: 3,
                }}
              >
                {loggedIn ? (
                  <Button variant="solid" onClick={goToProfile}>
                    Profile
                  </Button>
                ) : (
                  <>
                    <Button variant="solid" onClick={goToLogin}>
                      Login or Signup
                    </Button>
                  </>
                )}
              </div>

              <Center>
                <Box
                  bg="rgba(0, 0, 0, 0.5)" // Transparent black background
                  borderRadius="15px" // Rounded corners
                  p="4" // Padding
                  color="#fff" // Text color
                  width="90%"
                >
                  <VStack>
                    <div
                      className="App"
                      style={{
                        width: '90%',
                      }}
                    >
                      {data.eventFlyerPicture && (
                        <VStack>
                          <Image
                            boxSize="250"
                            src={data.eventFlyerPicture}
                            style={{ borderRadius: 25 }}
                          />
                          <Box w={240}>
                            <Text
                              textColor="white"
                              fontSize={14}
                              textAlign={'center'}
                            >
                              With Wave, your ID is your ticket. No resellers
                              and no fake tickets means a safer night out for
                              everyone.
                            </Text>
                          </Box>
                        </VStack>
                      )}
                      <div style={{}}>
                        <p
                          style={{
                            color: 'white',
                            fontSize: '50px',
                            padding: 0,
                            lineHeight: '1.3', // Adjust the value as needed
                          }}
                        >
                          {data.eventName}
                        </p>
                        <Text textColor="white" fontSize="25">
                          {data.venueName}
                        </Text>
                        <div style={{ height: 10 }} />
                        {eventTime && (
                          <Text textColor="white">
                            {to12HrTime(eventTime.toTimeString())}
                            {', '}
                            {eventTime.toDateString()}
                          </Text>
                        )}
                        <div style={{ height: 10 }} />
                        <Text color="white" display="flex" alignItems="center">
                          <Image
                            src="/tag-icon.png"
                            boxSize="5"
                            marginRight="0.5rem"
                            alt="Tag Icon"
                          />

                          {data.tags &&
                            data.tags.length > 0 &&
                            data.tags.map((item, index) => (
                              <React.Fragment key={index}>
                                {index !== 0 && ', '}
                                {item}
                              </React.Fragment>
                            ))}
                          {data.tags && data.tags.length === 0 && (
                            <React.Fragment>None</React.Fragment>
                          )}
                          <div style={{ width: 10 }} />
                          <Text
                            textColor="white"
                            display="flex"
                            alignItems="center"
                          >
                            <Image
                              src="/location-icon.png"
                              boxSize="5"
                              marginRight="0.5rem"
                            ></Image>
                            {data.location}
                          </Text>
                        </Text>

                        <div style={{ height: 15 }}></div>
                        <Accordion defaultIndex={[0]}>
                          {data.prices &&
                            data.prices.map((item, index) => (
                              <AccordionItem>
                                <div>
                                  <h2>
                                    <AccordionButton>
                                      {item.price === '0' && (
                                        <Box
                                          as="span"
                                          flex="1"
                                          textAlign="left"
                                          color="white"
                                        >
                                          {item.type}: Free
                                        </Box>
                                      )}
                                      {item.price !== '0' && (
                                        <Box
                                          as="span"
                                          flex="1"
                                          textAlign="left"
                                          color="white"
                                        >
                                          {item.type}: $
                                          {(item.price / 100).toFixed(2)}
                                        </Box>
                                      )}
                                      <AccordionIcon color={'white'} />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    {item.desc && (
                                      <Text>
                                        {item.desc}
                                        <br />
                                        <br />
                                      </Text>
                                    )}
                                    {inThePast && (
                                      <Button
                                        isDisabled
                                        onClick={() => {}}
                                        variant="solid"
                                      >
                                        Event has Passed
                                      </Button>
                                    )}
                                    {item.soldOut && !inThePast && (
                                      <Button
                                        isDisabled
                                        onClick={() => {}}
                                        variant="solid"
                                      >
                                        Sold Out
                                      </Button>
                                    )}
                                    {inThePast === false &&
                                      item.soldOut === false &&
                                      item.price === '0' && (
                                        <Button
                                          // HERE NEEDS TO BE CHANGED TO ADD DRAWER & STRIPE
                                          onClick={() =>
                                            drawerHook(item.price, index, 1)
                                          }
                                          variant="solid"
                                          isLoading={buyNowButtonLoading}
                                          // loadingText='Processing'
                                        >
                                          RSVP Now
                                        </Button>
                                      )}
                                    {inThePast === false &&
                                      item.soldOut === false &&
                                      item.price !== '0' && (
                                        <HStack>
                                          <Button
                                            // HERE NEEDS TO BE CHANGED TO ADD DRAWER & STRIPE
                                            onClick={() =>
                                              drawerHook(
                                                item.price * ticketCount,
                                                index,
                                                ticketCount
                                              )
                                            }
                                            variant="solid"
                                            isLoading={buyNowButtonLoading}
                                            // loadingText='Processing'
                                          >
                                            Buy Now
                                          </Button>
                                          <Button
                                            onClick={decrement}
                                            variant="outline"
                                            color="white"
                                            size="sm"
                                            disabled={buyNowButtonLoading}
                                          >
                                            -
                                          </Button>
                                          <div style={{ fontSize: 20 }}>
                                            {ticketCount}
                                          </div>
                                          <Button
                                            onClick={increment}
                                            variant="outline"
                                            color="white"
                                            size="sm"
                                            disabled={buyNowButtonLoading}
                                          >
                                            +
                                          </Button>
                                        </HStack>
                                      )}
                                  </AccordionPanel>
                                </div>
                              </AccordionItem>
                            ))}
                        </Accordion>
                        <div style={{ height: 10 }}></div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // height: '200px', // for example, the parent div height
                          }}
                        >
                          <Button onClick={RedeemRoute} variant="solid">
                            Redeem a Ticket Code
                          </Button>
                        </div>
                        <div style={{ height: 10 }}></div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // height: '200px', // for example, the parent div height
                          }}
                        >
                          {codeApplied && (
                            <Button
                              onClick={onDiscountOpen}
                              isDisabled={true}
                              variant="solid"
                            >
                              Discount Code Applied
                            </Button>
                          )}
                          {!codeApplied && (
                            <Button
                              onClick={onDiscountOpen}
                              isDisabled={false}
                              variant="solid"
                            >
                              Enter a Discount Code
                            </Button>
                          )}
                        </div>

                        <div style={{ height: 10 }}></div>
                        <Divider />
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <Text textColor="white" fontSize="25">
                            About
                          </Text>
                          <div style={{ height: 10 }} />
                          <Text textColor="white">
                            {data.ageLine1}{' '}
                            <Link
                              color="teal.500"
                              href={data.linkLine1}
                              isExternal
                            >
                              {data.linkLine1}
                            </Link>
                          </Text>
                          <br />
                          {data.contentLine2 && (
                            <Text textColor="white">
                              {data.contentLine2}{' '}
                              <Link
                                color="teal.500"
                                href={data.linkLine2}
                                isExternal
                              >
                                {data.linkLine2.slice(7)}
                              </Link>
                            </Text>
                          )}
                          {data.line2 && (
                            <>
                              <br />
                              <Text textColor="white">{data.line2}</Text>
                            </>
                          )}
                          <br />
                          <Text textColor="white">{data.line3}</Text>
                          <br />
                          <Text textColor="white">
                            {data.line4}{' '}
                            <Link
                              color="teal.500"
                              href={data.linkLine4}
                              isExternal
                            >
                              {data.linkLine4}
                            </Link>
                          </Text>
                          <br />
                          <Text textColor="white">
                            <Link
                              color="teal.500"
                              href="https://www.wavenightlife.com/terms-conditions"
                              isExternal
                            >
                              Terms and Conditions
                            </Link>{' '}
                            apply.
                          </Text>
                          <br />
                          <HStack>
                            <FaBullhorn style={{ color: 'white' }}></FaBullhorn>
                            <Text textColor="white">{data.line5}</Text>
                          </HStack>
                          <div style={{ height: 10 }} />
                        </div>
                        <Divider />
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <Text textColor="white" fontSize="25">
                            Lineup
                          </Text>
                          <div style={{ height: 10 }} />
                          {data.lineup &&
                            data.lineup.map((item, index) => (
                              <div>
                                <Flex
                                  direction="row"
                                  key={index}
                                  align="center"
                                  justify="space-between"
                                >
                                  <Flex align="center">
                                    <Image
                                      src={item.image}
                                      boxSize="65"
                                      alt="Your Image"
                                      borderRadius="full"
                                    />
                                    <Text
                                      textColor="white"
                                      marginLeft="1rem"
                                      fontWeight="bold"
                                    >
                                      {item.name}
                                    </Text>
                                  </Flex>

                                  <Spacer />

                                  <IconButton
                                    as={Link}
                                    href={item.instaLink}
                                    aria-label="Instagram"
                                    icon={
                                      <FaInstagram
                                        style={{ fontSize: '24px' }}
                                      />
                                    } // Adjust the fontSize as needed
                                  />

                                  <div style={{ width: 20 }} />
                                </Flex>
                                <div style={{ height: 10 }} />
                              </div>
                            ))}
                        </div>

                        <Divider />
                        {(data.venueMap ||
                          data.venueVideo ||
                          data.venuePics) && (
                          <div>
                            <Divider />
                            <div
                              style={{
                                paddingTop: 10,
                                paddingBottom: 30,
                              }}
                            >
                              <Text textColor="white" fontSize="25">
                                Take a Look
                              </Text>
                              <div style={{ height: 10 }} />
                              {/* video here */}
                              {data.venueVideo && (
                                <Box>
                                  <AspectRatio ratio={16 / 9}>
                                    <video controls>
                                      <source
                                        src={data.venueVideo}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </AspectRatio>
                                </Box>
                              )}
                              {data.venueMap && (
                                <Box>
                                  <div style={{ height: 10 }} />
                                  <Image src={data.venueMap}></Image>
                                </Box>
                              )}
                              {/* gallery here */}
                              {data.venuePics && (
                                <Box>
                                  <div style={{ height: 20 }} />
                                  <Grid
                                    templateColumns="repeat(2, 1fr)"
                                    gap={6}
                                  >
                                    {data.venuePics.map((image, index) => (
                                      <Image
                                        key={index}
                                        src={image}
                                        alt={`image-${index}`}
                                        boxSize="100%"
                                        objectFit="cover"
                                      />
                                    ))}
                                  </Grid>
                                </Box>
                              )}
                            </div>
                          </div>
                        )}
                        {data.sponsors && (
                          <div>
                            <Divider />
                            <div
                              style={{
                                paddingTop: 10,
                                paddingBottom: 30,
                                paddingRight: 15,
                              }}
                            >
                              <Text textColor="white" fontSize="25">
                                Sponsors
                              </Text>
                              <div style={{ height: 10 }} />
                              {data.sponsors && (
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    gap: '20px',
                                    justifyContent: 'center', // This helps center the last item if there's an odd number
                                  }}
                                >
                                  {data.sponsors.map((item, index) => (
                                    <div
                                      key={index} // Assuming 'item' is a unique value, you can use it as a key instead: key={item}
                                      style={{
                                        height: '60px', // Ensure the items take full width of the grid column
                                        display: 'flex',
                                        justifyContent: 'center', // Center the image within the grid column
                                      }}
                                    >
                                      <Image
                                        src={item}
                                        style={{
                                          maxWidth: '100%',
                                          height: 'auto',
                                        }} // Ensure the image scales with the column width and maintain its aspect ratio
                                      ></Image>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <Divider />
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <Text textColor="white" fontSize="25">
                            Venue
                          </Text>
                          <Text textColor="white" fontSize="35">
                            {data.venueName}
                          </Text>
                          <Text textColor="white">{data.address}</Text>
                          <br></br>
                          {data.address && (
                            <Button
                              onClick={() => handleDirections(data.address)}
                              leftIcon={
                                <HiOutlineLocationMarker
                                  style={{ height: 50 }}
                                />
                              }
                            >
                              Open in Maps
                            </Button>
                          )}
                          <br />
                          <br />
                          {doorsOpen && (
                            <Text textColor="white">
                              Doors open: {to12HrTime(doorsOpen.toTimeString())}
                              {' on '}
                              {doorsOpen.toDateString()}
                            </Text>
                          )}
                        </div>
                        <Divider />
                        <HStack>
                          <Box w={400}>
                            <div
                              style={{
                                paddingTop: 10,
                                color: 'white',
                                fontSize: 25,
                              }}
                            >
                              Download the Wave App
                            </div>
                            <div style={{ height: 10 }} />
                            <Text color="white">
                              The best live shows, parties, and VIP experiences
                              - all on one app. Catch the Wave today.
                            </Text>
                            <div style={{ height: 25 }} />
                            <HStack>
                              <Button
                                variant="solid"
                                // leftIcon={<FaApple />}
                                onClick={linkToAppStore}
                                isDisabled
                              >
                                Coming Soon
                                {/* iOS */}
                              </Button>
                              {/* <Button
                              variant="solid"
                              leftIcon={<AiFillAndroid />}
                              onClick={() =>
                                alert(
                                  'This will redirect to the android upon release'
                                )
                              }
                            >
                              Android
                            </Button> */}
                            </HStack>
                          </Box>
                        </HStack>
                      </div>
                    </div>
                  </VStack>
                </Box>
              </Center>
            </VStack>
          </div>
        )}

        {isDesktop && (
          <div>
            <img
              src={data.eventFlyerPicture}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -2,
                filter: 'blur(20px) brightness(80%)',
                objectFit: 'cover',
              }}
              alt="Blurry Image"
              className="blur-image"
            />
            <VStack>
              <div style={{ height: 150 }}></div>
              <div
                style={{
                  position: 'absolute',
                  top: '80px',
                  left: '200px',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2,
                }}
              >
                <a href="/">
                  <img
                    style={{ width: 300, height: 100 }}
                    src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                  />
                </a>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '80px',
                  right: '50px',
                  transform: 'translate(0%, -50%)',
                  zIndex: 1,
                }}
              >
                {loggedIn ? (
                  <Button variant="solid" onClick={goToProfile}>
                    Profile
                  </Button>
                ) : (
                  <>
                    <Button variant="solid" onClick={goToLogin}>
                      Login or Signup
                    </Button>
                  </>
                )}
              </div>

              <Center>
                <Flex direction="row" alignItems="flex-start">
                  <IconButton
                    icon={<ArrowBackIcon />}
                    onClick={goHome}
                    isRound
                    fontSize="24px"
                  />
                  <div style={{ width: 40 }} />
                  <Box
                    bg="rgba(0, 0, 0, 0.5)" // Transparent black background
                    borderRadius="15px" // Rounded corners
                    p="4" // Padding
                    color="#fff" // Text color
                  >
                    <Flex direction="row" alignItems="flex-start">
                      <HStack spacing={50}>
                        {data.eventFlyerPicture && (
                          <VStack>
                            <Image
                              boxSize="250"
                              src={data.eventFlyerPicture}
                              style={{ borderRadius: 25 }}
                            />
                            <Box w={240}>
                              <Text
                                textColor="white"
                                fontSize={14}
                                textAlign={'center'}
                              >
                                With Wave, your ID is your ticket. No resellers
                                and no fake tickets means a safer night out for
                                everyone.
                              </Text>
                            </Box>
                          </VStack>
                        )}
                        <div />
                      </HStack>
                      <HStack>
                        <div
                          className="App"
                          style={{
                            width: 700,
                          }}
                        >
                          <div style={{}}>
                            <p
                              style={{
                                color: 'white',
                                fontSize: '50px',
                                padding: 0,
                                lineHeight: '1.3', // Adjust the value as needed
                              }}
                            >
                              {data.eventName}
                            </p>
                            <Text textColor="white" fontSize="25">
                              {data.venueName}
                            </Text>
                            <div style={{ height: 10 }} />
                            {eventTime && (
                              <Text textColor="white">
                                {to12HrTime(eventTime.toTimeString())}
                                {', '}
                                {eventTime.toDateString()}
                              </Text>
                            )}
                            <div style={{ height: 10 }} />
                            <Text
                              color="white"
                              display="flex"
                              alignItems="center"
                            >
                              <Image
                                src="/tag-icon.png"
                                boxSize="5"
                                marginRight="0.5rem"
                                alt="Tag Icon"
                              />

                              {data.tags &&
                                data.tags.length > 0 &&
                                data.tags.map((item, index) => (
                                  <React.Fragment key={index}>
                                    {index !== 0 && ', '}
                                    {item}
                                  </React.Fragment>
                                ))}
                              {data.tags && data.tags.length === 0 && (
                                <React.Fragment>None</React.Fragment>
                              )}
                              <div style={{ width: 10 }} />
                              <Text
                                textColor="white"
                                display="flex"
                                alignItems="center"
                              >
                                <Image
                                  src="/location-icon.png"
                                  boxSize="5"
                                  marginRight="0.5rem"
                                ></Image>
                                {data.location}
                              </Text>
                            </Text>

                            <div style={{ height: 15 }}></div>
                            <Accordion defaultIndex={[0]}>
                              {data.prices &&
                                data.prices.map((item, index) => (
                                  <AccordionItem>
                                    <div>
                                      <h2>
                                        <AccordionButton>
                                          {item.price === '0' && (
                                            <Box
                                              as="span"
                                              flex="1"
                                              textAlign="left"
                                              color="white"
                                            >
                                              {item.type}: Free
                                            </Box>
                                          )}
                                          {item.price !== '0' && (
                                            <Box
                                              as="span"
                                              flex="1"
                                              textAlign="left"
                                              color="white"
                                            >
                                              {item.type}: $
                                              {(item.price / 100).toFixed(2)}
                                            </Box>
                                          )}
                                          <AccordionIcon color={'white'} />
                                        </AccordionButton>
                                      </h2>
                                      <AccordionPanel pb={4}>
                                        {item.desc && (
                                          <Text>
                                            {item.desc}
                                            <br />
                                            <br />
                                          </Text>
                                        )}
                                        {inThePast && (
                                          <Button
                                            isDisabled
                                            onClick={() => {}}
                                            variant="solid"
                                          >
                                            Event has Passed
                                          </Button>
                                        )}
                                        {item.soldOut && !inThePast && (
                                          <Button
                                            isDisabled
                                            onClick={() => {}}
                                            variant="solid"
                                          >
                                            Sold Out
                                          </Button>
                                        )}
                                        {inThePast === false &&
                                          item.soldOut === false &&
                                          item.price === '0' && (
                                            <Button
                                              // HERE NEEDS TO BE CHANGED TO ADD DRAWER & STRIPE
                                              onClick={() =>
                                                drawerHook(item.price, index, 1)
                                              }
                                              variant="solid"
                                              isLoading={buyNowButtonLoading}
                                              // loadingText='Processing'
                                            >
                                              RSVP Now
                                            </Button>
                                          )}
                                        {inThePast === false &&
                                          item.soldOut === false &&
                                          item.price !== '0' && (
                                            <HStack>
                                              <Button
                                                // HERE NEEDS TO BE CHANGED TO ADD DRAWER & STRIPE
                                                onClick={() =>
                                                  drawerHook(
                                                    item.price * ticketCount,
                                                    index,
                                                    ticketCount
                                                  )
                                                }
                                                variant="solid"
                                                isLoading={buyNowButtonLoading}
                                                // loadingText='Processing'
                                              >
                                                Buy Now
                                              </Button>
                                              <Button
                                                onClick={decrement}
                                                variant="outline"
                                                color="white"
                                                size="sm"
                                                disabled={buyNowButtonLoading}
                                              >
                                                -
                                              </Button>
                                              <div style={{ fontSize: 20 }}>
                                                {ticketCount}
                                              </div>
                                              <Button
                                                onClick={increment}
                                                variant="outline"
                                                color="white"
                                                size="sm"
                                                disabled={buyNowButtonLoading}
                                              >
                                                +
                                              </Button>
                                            </HStack>
                                          )}
                                      </AccordionPanel>
                                    </div>
                                  </AccordionItem>
                                ))}
                            </Accordion>
                            <div style={{ height: 10 }}></div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // height: '200px', // for example, the parent div height
                              }}
                            >
                              <Button onClick={RedeemRoute} variant="solid">
                                Redeem a Ticket Code
                              </Button>
                            </div>
                            <div style={{ height: 10 }}></div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // height: '200px', // for example, the parent div height
                              }}
                            >
                              {codeApplied && (
                                <Button
                                  onClick={onDiscountOpen}
                                  isDisabled={true}
                                  variant="solid"
                                >
                                  Discount Code Applied
                                </Button>
                              )}
                              {!codeApplied && (
                                <Button
                                  onClick={onDiscountOpen}
                                  isDisabled={false}
                                  variant="solid"
                                >
                                  Enter a Discount Code
                                </Button>
                              )}
                            </div>

                            <div style={{ height: 10 }}></div>
                            <Divider />
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Text textColor="white" fontSize="25">
                                About
                              </Text>
                              <div style={{ height: 10 }} />
                              <Text textColor="white">
                                {data.ageLine1}{' '}
                                <Link
                                  color="teal.500"
                                  href={data.linkLine1}
                                  isExternal
                                >
                                  {data.linkLine1}
                                </Link>
                              </Text>
                              <br />
                              {data.contentLine2 && (
                                <Text textColor="white">
                                  {data.contentLine2}{' '}
                                  <Link
                                    color="teal.500"
                                    href={data.linkLine2}
                                    isExternal
                                  >
                                    {data.linkLine2.slice(7)}
                                  </Link>
                                </Text>
                              )}
                              {data.line2 && (
                                <>
                                  <br />
                                  <Text textColor="white">{data.line2}</Text>
                                </>
                              )}
                              <br />
                              <Text textColor="white">{data.line3}</Text>
                              <br />
                              <Text textColor="white">
                                {data.line4}{' '}
                                <Link
                                  color="teal.500"
                                  href={data.linkLine4}
                                  isExternal
                                >
                                  {data.linkLine4}
                                </Link>
                              </Text>
                              <br />
                              <Text textColor="white">
                                <Link
                                  color="teal.500"
                                  href="https://www.wavenightlife.com/terms-conditions"
                                  isExternal
                                >
                                  Terms and Conditions
                                </Link>{' '}
                                apply.
                              </Text>
                              <br />
                              <HStack>
                                <FaBullhorn
                                  style={{ color: 'white' }}
                                ></FaBullhorn>
                                <Text textColor="white">{data.line5}</Text>
                              </HStack>
                              <div style={{ height: 10 }} />
                            </div>
                            <Divider />
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Text textColor="white" fontSize="25">
                                Lineup
                              </Text>
                              <div style={{ height: 10 }} />
                              {data.lineup &&
                                data.lineup.map((item, index) => (
                                  <div>
                                    <Flex
                                      direction="row"
                                      key={index}
                                      align="center"
                                      justify="space-between"
                                    >
                                      <Flex align="center">
                                        <Image
                                          src={item.image}
                                          boxSize="70"
                                          alt="Your Image"
                                          borderRadius="full" // Adjust `100px` as needed for your desired height.

                                          // objectFit="cover" // If you are okay with cropping.
                                        />
                                        <Text
                                          textColor="white"
                                          marginLeft="1rem"
                                          fontWeight="bold"
                                          fontSize="25"
                                        >
                                          {item.name}
                                        </Text>
                                      </Flex>

                                      <Spacer />

                                      <IconButton
                                        as={Link}
                                        href={item.instaLink}
                                        aria-label="Instagram"
                                        icon={
                                          <FaInstagram
                                            style={{ fontSize: '24px' }}
                                          />
                                        } // Adjust the fontSize as needed
                                      />

                                      <div style={{ width: 20 }} />
                                    </Flex>
                                    <div style={{ height: 10 }} />
                                  </div>
                                ))}
                            </div>
                            {(data.venueMap ||
                              data.venueVideo ||
                              data.venuePics) && (
                              <div>
                                <Divider />
                                <div
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 30,
                                  }}
                                >
                                  <Text textColor="white" fontSize="25">
                                    Take a Look
                                  </Text>
                                  <div style={{ height: 10 }} />
                                  {/* video here */}
                                  {data.venueVideo && (
                                    <Box>
                                      <AspectRatio ratio={16 / 9}>
                                        <video controls>
                                          <source
                                            src={data.venueVideo}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      </AspectRatio>
                                    </Box>
                                  )}
                                  {data.venueMap && (
                                    <Box>
                                      <div style={{ height: 10 }} />
                                      <Image src={data.venueMap}></Image>
                                    </Box>
                                  )}
                                  {/* gallery here */}
                                  {data.venuePics && (
                                    <Box>
                                      <div style={{ height: 20 }} />
                                      <Grid
                                        templateColumns="repeat(2, 1fr)"
                                        gap={6}
                                      >
                                        {data.venuePics.map((image, index) => (
                                          <Image
                                            key={index}
                                            src={image}
                                            alt={`image-${index}`}
                                            boxSize="100%"
                                            objectFit="cover"
                                          />
                                        ))}
                                      </Grid>
                                    </Box>
                                  )}
                                </div>
                              </div>
                            )}
                            {data.sponsors && (
                              <div>
                                <Divider />
                                <div
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 30,
                                    paddingRight: 15,
                                  }}
                                >
                                  <Text textColor="white" fontSize="25">
                                    Sponsors
                                  </Text>
                                  <div style={{ height: 10 }} />
                                  {data.sponsors && (
                                    <div
                                      style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                        gap: '30px',
                                        justifyContent: 'center', // This helps center the last item if there's an odd number
                                      }}
                                    >
                                      {data.sponsors.map((item, index) => (
                                        <div
                                          key={index} // Assuming 'item' is a unique value, you can use it as a key instead: key={item}
                                          style={{
                                            height: '100px', // Ensure the items take full width of the grid column
                                            display: 'flex',
                                            justifyContent: 'center', // Center the image within the grid column
                                          }}
                                        >
                                          <Image
                                            src={item}
                                            style={{
                                              maxWidth: '100%',
                                              height: 'auto',
                                            }} // Ensure the image scales with the column width and maintain its aspect ratio
                                          ></Image>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <Divider />
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Text textColor="white" fontSize="25">
                                Venue
                              </Text>
                              <Text textColor="white" fontSize="35">
                                {data.venueName}
                              </Text>
                              <Text textColor="white">{data.address}</Text>
                              <br></br>
                              {data.address && (
                                <Button
                                  onClick={() => handleDirections(data.address)}
                                  leftIcon={
                                    <HiOutlineLocationMarker
                                      style={{ height: 50 }}
                                    />
                                  }
                                >
                                  Open in Maps
                                </Button>
                              )}
                              <br />
                              <br />
                              {doorsOpen && (
                                <Text textColor="white">
                                  Doors open:{' '}
                                  {to12HrTime(doorsOpen.toTimeString())}
                                  {' on '}
                                  {doorsOpen.toDateString()}
                                </Text>
                              )}
                            </div>
                            <Divider />
                            <HStack>
                              <Box w={400}>
                                <div
                                  style={{
                                    paddingTop: 10,
                                    color: 'white',
                                    fontSize: 25,
                                  }}
                                >
                                  Download the Wave App
                                </div>
                                <div style={{ height: 10 }} />
                                <Text color="white">
                                  The best live shows, parties, and VIP
                                  experiences - all on one app. Catch the Wave
                                  today.
                                </Text>
                                <div style={{ height: 25 }} />
                                <HStack>
                                  <Button
                                    variant="solid"
                                    // leftIcon={<FaApple />}
                                    onClick={linkToAppStore}
                                    isDisabled
                                  >
                                    Coming Soon
                                    {/* iOS */}
                                  </Button>
                                  {/* <Button
                                  variant="solid"
                                  leftIcon={<AiFillAndroid />}
                                  onClick={() =>
                                    alert(
                                      'This will redirect to the android upon release'
                                    )
                                  }
                                >
                                  Android
                                </Button> */}
                                </HStack>
                              </Box>
                              <div style={{ width: 100 }} />
                              <Image src="/waveappicon1.png" boxSize="130" />
                            </HStack>
                          </div>
                        </div>
                      </HStack>
                    </Flex>
                  </Box>
                </Flex>
              </Center>
            </VStack>
          </div>
        )}
        <Drawer
          isOpen={isOpen}
          placement="right"
          initialFocusRef={firstField}
          onClose={closeDrawer}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              Complete your Purchase
            </DrawerHeader>
            <Elements stripe={stripePromise()} options={options}>
              {passIndex != -1 && (
                <MyPaymentForm
                  closeFunction={closeDrawer}
                  openDrawerFunction={onInfoOpen}
                  setCodes={setTicketCodes}
                  setCodeLoading={setCodesLoading}
                  passDetails={{
                    passName: `${data.passName}: ${data.prices[passIndex].displayType}`,
                    datePurchased: new Date().toISOString(),
                    isActive: true,
                    eventTime: data.eventTime,
                    eventEndTime: data.eventEndTime,
                    venueId: data.venueId,
                    id: pathArray[2],
                    passImage: data.eventFlyerPicture,
                    ticketType: 'event',
                    price: intentPrice,
                    discountCode: usedDiscountCode,
                    numTickets: 0,
                    taxAmount: taxAmt,
                    waveFeeAmount: waveFee,
                  }}
                  index={passIndex}
                  id={pathArray[2]}
                  numTickets={ticketCount}
                  passPrice={intentPrice}
                  origPrice={data.prices[passIndex].price}
                  taxRate={taxRate}
                ></MyPaymentForm>
              )}
            </Elements>
          </DrawerContent>
        </Drawer>
      </ChakraProvider>
      <ChakraProvider theme={theme}>
        <AlertDialog
          isOpen={isDiscountOpen}
          leastDestructiveRef={cancelRef}
          onClose={onDiscountClose}
          isCentered={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Discount Code Information
              </AlertDialogHeader>

              <AlertDialogBody>
                Enter your discount code below to receive a discount on your
                ticket!
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaTag />} />
                  <Input
                    type="text"
                    placeholder="Enter Discount Code"
                    value={discountCode}
                    onChange={e => setDiscountCode(e.target.value)}
                  />
                </InputGroup>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDiscountClose}>
                  No, Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={handleDiscountCode}
                  isLoading={discountButtonLoading}
                  ml={3}
                >
                  Yes, Submit
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </ChakraProvider>
      <ChakraProvider theme={theme}>
        <AlertDialog
          isOpen={isInfoOpen}
          leastDestructiveRef={cancelRef}
          onClose={onInfoClose}
          isCentered={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Ticket Information
              </AlertDialogHeader>
              {loadingCodes && (
                <AlertDialogBody>
                  Loading... Don't Reload The Page
                </AlertDialogBody>
              )}
              {!loadingCodes && (
                <div>
                  <AlertDialogBody>
                    Congratulations on your ticket purchase! Since you purchased
                    this ticket, your ticket is already attached to your
                    account. Press the button below to navigate to the profile
                    page, where you can share the codes for additional tickets
                    with your friends.
                  </AlertDialogBody>
                  <AlertDialogBody>
                    A Wave Pass is attached to an account, and your ID is your
                    ticket - no need to scan any barcodes at the venue, just
                    simply provide your ID and your ticket will be processed.
                  </AlertDialogBody>
                </div>
              )}

              <AlertDialogFooter>
                <Button
                  colorScheme="blue"
                  ref={cancelRef}
                  onClick={goToProfile}
                >
                  Continue
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </ChakraProvider>
    </>
  );
}

export default EventPage;
