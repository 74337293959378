import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import Home from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/404';
import VenuePage from './pages/Venue';
import EventPage from './pages/Event';
import Profile from './pages/Profile';
import Authflow from './pages/Authflow';
import CreateUser from './pages/CreateUser';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import Redeem from './pages/Redeem';
import Footer from './components/Footer';

function App() {
  return (
    <Box
      textAlign="top"
      fontSize="xl"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      <BrowserRouter>
        <Box flexGrow={1}>
          <Routes>
            <Route path="/" element={<Home />} exact={true} />
            <Route path="/login" element={<Login />} exact={true} />
            <Route path="/login?redirect=:pageName" element={<Login />}></Route>
            <Route path="/profile" element={<Profile />} exact={true} />
            <Route path="/authflow" element={<Authflow />} exact={true} />
            <Route path="/createuser" element={<CreateUser />} exact={true} />
            <Route path="/success" element={<Success />} exact={true} />
            <Route path="/cancel" element={<Cancel />} exact={true} />
            <Route path="/redeem" element={<Redeem />} exact={true} />
            {/* These two below should be pages that redirect to homepage? or just 404 error */}
            {/* <Route path="/venues/" element={<Home />} exact={true}/>
            <Route path="/events/" element={<Home />} exact={true}/> */}
            <Route
              path="/venues/:venueName"
              element={<VenuePage />}
              exact={true}
            />
            <Route
              path="/events/:eventName"
              element={<EventPage />}
              exact={true}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
        <Footer/>
      </BrowserRouter>
    </Box>
  );
}

export default App;
