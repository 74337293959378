import React, { useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  useToast,
} from '@chakra-ui/react';
import {
  ChakraProvider,
  theme,
  Badge,
  Text,
  Button,
  Center,
  Image,
  Input,
  Box,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
} from '@chakra-ui/react';
import { db, auth } from '../firebase-config';
import {
  getDoc,
  doc,
  getDocs,
  where,
  collection,
  query,
} from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router';
import ButtonTheme from '../components/Button.js';
import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive';

function Profile() {
  const {
    isOpen: isFriendsOpen,
    onOpen: onFriendsOpen,
    onClose: onFriendsClose,
  } = useDisclosure();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(new Map());
  const [codeStatus, setCodeStatus] = useState(new Map());
  const [eventTime, setEventTime] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCode, setSelectedCode] = useState(null);
  const [smsNumber, setSmsNumber] = useState('+1');
  const [isInvalid, setIsInvalid] = useState(false);
  const [sentRequests, setSentRequests] = useState([]);
  const [recvdReqs, setRecvdRequests] = useState([]);
  const [names, setNames] = useState({});
  const [friends, setFriends] = useState([]);
  const [requestingFriend, setRequestingFriend] = useState(false);
  const [friendRequestsChanged, setFriendRequestsChanged] = useState(false);
  const [ticketTransferLoading, setTicketTransferLoading] = useState(false);
  const [ticketTransferred, setTicketTransferred] = useState(false);
  const toast = useToast();
  const handleInviteClick = code => {
    setSelectedCode(code);
    onOpen();
  };

  function signOutFunction() {
    signOut(auth);
    window.location.reload(false);
  }

  function goToCreateUser() {
    navigate('/createuser');
  }

  function goToRedeem() {
    navigate('/redeem');
  }

  async function sendTicket(uid, ticketCode) {
    setTicketTransferLoading(true);
    const phone = await getNumFromUID(uid);
    const functions = getFunctions();
    const assignTicket = httpsCallable(functions, 'assignTicket');
    assignTicket({ receiver: phone, code: ticketCode })
      .then(result => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        console.log(data);
        if (data.status === 200) {
          toast({
            title: 'Ticket transfer successful!',
            description: 'We hope you enjoy your Wave Product!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
          setTicketTransferLoading(false);
          onClose();
        } else {
          toast({
            title: 'Ticket transfer failed.',
            description:
              'The user might already have this pass. If not, contact Wave Support.',
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
          setTicketTransferLoading(false);
        }
      })
      .catch(error => {
        // toast({
        //   title: 'Code redemption failed.',
        //   description:
        //     'Double check your code and try again. If the problem persists, contact Wave Support.',
        //   status: 'error',
        //   duration: 9000,
        //   isClosable: true,
        //   position: 'bottom-right',
        // });
        toast({
          title: 'Ticket transfer failed.',
          description:
            'The user might already have this pass. If not, contact Wave Support.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
        setTicketTransferLoading(false);
        console.log(error);
      });
    setTicketTransferLoading(false);
    const userUID = auth.currentUser.uid;
    const snap = await getDoc(doc(db, 'Users', userUID));
    let tempData = snap.data();

    tempData['passes'] = [];

    let newCodeStatus = new Map(codeStatus);

    for (var i = 0; i < snap.data()['passes'].length; i++) {
      if (snap.data()['passes'][i]['isActive']) {
        tempData['passes'].push(snap.data()['passes'][i]);
      }

      if (snap.data()['passes'][i]['codes']) {
        // search for this code in the database
        for (var j = 0; j < snap.data()['passes'][i]['codes'].length; j++) {
          const codesQuery = query(
            collection(db, 'activeCodes'),
            where('code', '==', snap.data()['passes'][i]['codes'][j])
          );
          const querySnapshot = await getDocs(codesQuery);
          if (querySnapshot.empty) {
            // code not found
            newCodeStatus.set(snap.data()['passes'][i]['codes'][j], 'Error');
          } else {
            const codeDocSnap = querySnapshot.docs[0];
            const codeDocData = codeDocSnap.data();
            var tempStatus = '';
            if (codeDocData['active']) {
              newCodeStatus.set(
                snap.data()['passes'][i]['codes'][j],
                'Not Redeemed'
              );
            } else {
              newCodeStatus.set(
                snap.data()['passes'][i]['codes'][j],
                'Redeemed'
              );
            }
          }
          setCodeStatus(new Map(newCodeStatus));
        }
      }
    }

    setUserData(tempData);
  }

  const handleSmsNumberChange = (event) => {
    let value = event.target.value;

    // Check if the '+1' prefix is being removed or altered
    if (!value.startsWith('+1')) {
      value = '+1' + value.replace(/[^\d]/g, '');
    }

    // Remove all non-numeric characters except leading "+"
    let cleanedValue = value.replace(/[^\d\+]/g, '');

    // Handle cases where the pasted number includes '+1' leading to duplicates
    if (cleanedValue.startsWith('+1+1')) {
      cleanedValue = '+1' + cleanedValue.slice(4);
    }

    // Ensure the value always starts with '+1'
    if (!cleanedValue.startsWith('+1')) {
      cleanedValue = '+1' + cleanedValue.replace(/^\+1/, '');
    }

    // Validation for US phone numbers
    const isValid = /^\+1\d{10}$/.test(cleanedValue);

    setIsInvalid(!isValid);
    setSmsNumber(cleanedValue);
  };

  const handleSubmit = selectedCode => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Event Pass Code',
          text: `Here's your code for the event: ${selectedCode}`,
          // url: 'https://example.com/event'
        })
        .then(() => console.log('Share was successful.'))
        .catch(error => console.error('Sharing failed', error));
    } else {
      // Fallback: Prompt the user to share manually
      alert('Failed to share the code. Please share your codes manually.');
    }
    onClose(); // Close the modal
  };

  useEffect(() => {
    if (userData) {
      setFriends(userData['friends']);
    }
  }, [userData]);

  async function getNameFromUID(uid) {
    const snap = await getDoc(doc(db, 'Users', uid));
    return snap.data()['firstName'] + ' ' + snap.data()['lastName'];
  }

  async function getNumFromUID(uid) {
    const snap = await getDoc(doc(db, 'Users', uid));
    return snap.data()['phoneNumber'];
  }

  useEffect(() => {
    const fetchNames = async () => {
      const namesMap = {};
      if (friends && sentRequests && recvdReqs) {
        if (sentRequests.length !== 0) {
          for (const request of sentRequests) {
            const name = await getNumFromUID(request.receiver);
            namesMap[request.receiver] = name;
          }
        }
        if (recvdReqs.length !== 0) {
          for (const request of recvdReqs) {
            const name = await getNameFromUID(request.sender);
            namesMap[request.sender] = name;
          }
        }
        if (friends.length !== 0) {
          for (const friend of friends) {
            const name = await getNameFromUID(friend);
            namesMap[friend] = name;
          }
        }

        setNames(namesMap);
        console.log(names);
      }
    };

    fetchNames();
  }, [sentRequests, recvdReqs, userData]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        setLoggedIn(true);
        setLoading(false);
        const userUID = auth.currentUser.uid;

        const snap = await getDoc(doc(db, 'Users', userUID));
        if (
          snap.data()['birthdate'] === '' ||
          snap.data()['firstName'] === '' ||
          snap.data()['lastName'] === ''
        ) {
          signOutFunction();
        }

        let tempData = snap.data();

        tempData['passes'] = [];

        let newCodeStatus = new Map(codeStatus);

        for (var i = 0; i < snap.data()['passes'].length; i++) {
          if (snap.data()['passes'][i]['isActive']) {
            tempData['passes'].push(snap.data()['passes'][i]);
          }

          if (snap.data()['passes'][i]['codes']) {
            // search for this code in the database
            for (var j = 0; j < snap.data()['passes'][i]['codes'].length; j++) {
              const codesQuery = query(
                collection(db, 'activeCodes'),
                where('code', '==', snap.data()['passes'][i]['codes'][j])
              );
              const querySnapshot = await getDocs(codesQuery);
              if (querySnapshot.empty) {
                // code not found
                newCodeStatus.set(
                  snap.data()['passes'][i]['codes'][j],
                  'Error'
                );
              } else {
                const codeDocSnap = querySnapshot.docs[0];
                const codeDocData = codeDocSnap.data();
                var tempStatus = '';
                if (codeDocData['active']) {
                  newCodeStatus.set(
                    snap.data()['passes'][i]['codes'][j],
                    'Not Redeemed'
                  );
                } else {
                  newCodeStatus.set(
                    snap.data()['passes'][i]['codes'][j],
                    'Redeemed'
                  );
                }
              }
              setCodeStatus(new Map(newCodeStatus));
            }
          }
        }

        setUserData(tempData);
      } else {
        // User is signed out
        setLoggedIn(false);
        setLoading(false);
        goHome();
      }
    });

    document.body.style.background = '#141416';
    return () => {
      unsubscribe();
    };
  }, [friendRequestsChanged, ticketTransferred]);

  useEffect(() => {
    const doThing = async () => {
      if (loggedIn) {
        const userUID = auth.currentUser.uid;

        const sentReqsQuery = query(
          collection(db, 'friendRequests'),
          where('sender', '==', userUID),
          where('status', '==', 'pending')
        );
        const sentReqs = await getDocs(sentReqsQuery);
        setSentRequests(sentReqs.docs.map(doc => doc.data()));

        const recvdReqsQuery = query(
          collection(db, 'friendRequests'),
          where('receiver', '==', userUID),
          where('status', '==', 'pending')
        );
        const recvdReqs = await getDocs(recvdReqsQuery);

        const snap = await getDoc(doc(db, 'Users', userUID));
        // Include the doc ID of each request
        setRecvdRequests(
          recvdReqs.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        );
      }
    };

    doThing();
  }, [userData]);

  const navigate = useNavigate();
  function goHome() {
    navigate('/');
  }

  function friendAction(request, approve) {
    const actionStr = approve ? 'approve' : 'deny';

    const functions = getFunctions();
    const friendRequestAction = httpsCallable(functions, 'friendRequestAction');
    friendRequestAction({ docID: request.id, action: actionStr })
      .then(result => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        if (data.status === 200) {
          if (data.message === 'Friend request denied') {
            toast({
              title: 'Friend request denied successfully!',
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
          } else {
            toast({
              title: 'Friend request approved successfully!',
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
          }
          // Trigger the useEffect to re-fetch the data
          setFriendRequestsChanged(prev => !prev);
        } else {
          toast({
            title: 'Friend request action failed.',
            description: 'If the problem persists, contact support.',
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast({
          title: 'Friend request action failed.',
          description: 'If the problem persists, contact support.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      });
  }

  function handleFriends() {
    // once submitted, change modal to loading state
    setRequestingFriend(true);
    // then call firebase function
    const functions = getFunctions();
    const friendRequest = httpsCallable(functions, 'friendRequest');
    friendRequest({ receiver: smsNumber, message: 'Join me on Wave!' })
      .then(result => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;

        if (data.status === 200) {
          toast({
            title: 'Friend request sent successfully!',
            // description: 'We hope you enjoy your Wave Product!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });

          onFriendsClose();
          setRequestingFriend(false);
        } else if (data.status === 400) {
          toast({
            title: 'Code redemption failed.',
            description: 'Friend request failed! Please try again.',
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
          setRequestingFriend(false);
        } else {
          toast({
            title: 'Code redemption failed.',
            description:
              'Friend request failed! Unknown error. If the problem persists, contact Wave Support.',
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
          setRequestingFriend(false);
        }
        // if (data === 'Success') {
        //   toast({
        //     title: 'Code redemption successful!.',
        //     description: 'We hope you enjoy your Wave Product!',
        //     status: 'success',
        //     duration: 9000,
        //     isClosable: true,
        //     position: 'bottom-right',
        //   });
        // } else {
        //   toast({
        //     title: 'Code redemption failed.',
        //     description:
        //       'Double check your code and try again. If the problem persists, contact Wave Support.',
        //     status: 'error',
        //     duration: 9000,
        //     isClosable: true,
        //     position: 'bottom-right',
        //   });
        // }
      })
      .catch(error => {
        // toast({
        //   title: 'Code redemption failed.',
        //   description:
        //     'Double check your code and try again. If the problem persists, contact Wave Support.',
        //   status: 'error',
        //   duration: 9000,
        //   isClosable: true,
        //   position: 'bottom-right',
        // });
        console.log(error);
      });

    // on success close modal
    // otherwise keep it open
  }

  function formatPhoneNumber(phoneNumber) {
    const match = phoneNumber.match(/^\+1(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    } else {
      return phoneNumber; // Return the original if it doesn't match the expected format
    }
  }

  function convertBirthdate(birthdate) {
    const [year, month, day] = birthdate.split('-');
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    let dateTimes = [];
    if (userData.passes) {
      for (let i = 0; i < userData.passes.length; i++) {
        if (
          userData.passes[i].eventTime &&
          userData.passes[i].eventTime.seconds
        ) {
          const eventTimeSeconds = userData.passes[i].eventTime.seconds;
          const date = new Date(0); // Create a new date object with timestamp 0 (epoch)
          date.setUTCSeconds(eventTimeSeconds);

          // Options for toLocaleString to get the desired format
          const options = {
            weekday: 'short', // "Sat"
            year: 'numeric', // "2024"
            month: 'short', // "Mar"
            day: 'numeric', // "16"
            hour: 'numeric', // "12 AM/PM"
            minute: '2-digit', // "00"
            hour12: true, // 12hr format
          };

          // Convert to local string with specified options
          const dateString = date.toLocaleString('en-US', options);

          // Replace the comma after the time with "pm" or "am"
          const formattedDateString = dateString
            .replace(/,\s(?=\d{1,2}:\d{2}\s(?:AM|PM))/, '')
            .replace(/(\d{4})/, '$1 ');

          dateTimes.push(formattedDateString);
        }
      }
      setEventTime(dateTimes);
    }
  }, [userData]);

  return (
    <ChakraProvider theme={ButtonTheme}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', // Container takes at least full viewport height
        }}
      >
        {isDesktop && (
          <div>
            <div
              style={{
                position: 'absolute',
                top: '80px',
                left: '200px',
                transform: 'translate(-50%, -50%)',
                zIndex: -1,
              }}
            >
              <a href="/">
                <img
                  style={{ width: 300, height: 100 }}
                  src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                />
              </a>
            </div>
            <Image
              src="/blurred_background.png"
              style={{
                position: 'fixed', // Changed to 'fixed' to cover the whole page
                top: 0,
                left: 0,
                width: '100%',
                minHeight: '100vh', // Ensure it covers at least the viewport height
                objectFit: 'cover',
                zIndex: -3,
              }}
            />
            <div
              id="content-container"
              style={{
                position: 'relative',
                top: '50px', // Changed to a fixed value from the top
                paddingBottom: '80px',
                width: '80%',
                left: '50%',
                transform: 'translateX(-50%)', // Only translate horizontally
                zIndex: 2,
                textAlign: 'center',
                color: '#fff',
              }}
            >
              <div style={{ height: '120px' }} />
              <HStack>
                {auth.currentUser && userData.birthdate && (
                  <Box
                    bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                    p={4} // padding
                    borderRadius="lg" // rounded corners
                  >
                    <FormControl id="first-last-name" mb={4}>
                      <FormLabel color="black">Name</FormLabel>
                      <Box p={2} bg="white" borderRadius="md" color="black">
                        {userData['firstName']} {userData['lastName']}
                      </Box>
                    </FormControl>

                    <FormControl id="email" mb={4}>
                      <FormLabel color="black">Email</FormLabel>
                      <Box p={2} bg="white" borderRadius="md" color="black">
                        {userData['email']}
                      </Box>
                    </FormControl>

                    <FormControl id="birthdate" mb={4}>
                      <FormLabel color="black">Birthdate</FormLabel>
                      <Box p={2} bg="white" borderRadius="md" color="black">
                        {convertBirthdate(userData['birthdate'])}
                      </Box>
                    </FormControl>

                    <FormControl id="phone-number">
                      <FormLabel color="black">Phone Number</FormLabel>
                      <Box p={2} bg="white" borderRadius="md" color="black">
                        {formatPhoneNumber(auth.currentUser.phoneNumber)}
                      </Box>
                    </FormControl>
                  </Box>
                )}
                {/* <div style={{ height: '20px' }} /> */}
                <VStack>
                  <Box
                    bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                    p={4} // padding
                    borderRadius="lg" // rounded corners
                  >
                    Welcome to Wave! With Wave, your ID is your ticket. No
                    resellers and no fake tickets means a safer night out for
                    everyone. <br />
                    Have a code? Redeem it below. <br />
                    <Button variant="solid" onClick={goToRedeem}>
                      Redeem Code
                    </Button>
                  </Box>
                  <Box
                    bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                    p={4} // padding
                    borderRadius="lg" // rounded corners
                  >
                    The best live shows, parties, and VIP experiences - all on
                    one app. Catch the Wave today. Coming soon to iOS and
                    Android. <br />
                    {/* <HStack> */}
                    <Button
                      variant="solid"
                      // leftIcon={<FaApple />}
                      onClick={() => (window.location.href = '/')}
                      isDisabled
                    >
                      Coming Soon
                    </Button>
                  </Box>
                </VStack>
              </HStack>

              <div style={{ height: '20px' }} />
              <VStack spacing={4} w="full">
                <Box
                  bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                  p={4} // padding
                  borderRadius="lg" // rounded corners
                  w="100%"
                >
                  My Friends <br />
                  {/* make an accordion with sections for friends list, incoming friend requests, sent friend requests */}
                  <Accordion allowToggle>
                    {userData['friends'] && (
                      <AccordionItem>
                        <AccordionButton>
                          Friends List <AccordionIcon />{' '}
                        </AccordionButton>
                        <AccordionPanel>
                          {userData['friends'].map(friend => (
                            <Box key={friend}>
                              {names[friend] || 'Loading...'}
                            </Box>
                          ))}
                          {userData['friends'].length === 0 && (
                            <Text>No friends added yet!</Text>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    )}
                    <AccordionItem>
                      <AccordionButton>
                        Incoming Requests <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {recvdReqs.map(request => (
                          <Box key={request.sender}>
                            {names[request.sender] || 'Loading...'}{' '}
                            <Button
                              onClick={() => friendAction(request, true)}
                              size="xs"
                            >
                              Approve
                            </Button>{' '}
                            <Button
                              onClick={() => friendAction(request, false)}
                              size="xs"
                            >
                              Deny
                            </Button>
                          </Box>
                        ))}
                        {recvdReqs.length === 0 && (
                          <Text>No requests received yet!</Text>
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton>
                        Sent Requests <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {sentRequests.map(request => (
                          <Box key={request.receiver}>
                            {names[request.receiver] || 'Loading...'}{' '}
                            {/* <Button
                                onClick={() => friendAction(request, false)}
                                size="xs"
                              >
                                Delete
                              </Button> */}
                          </Box>
                        ))}
                        {sentRequests.length === 0 && (
                          <Text>No requests sent yet!</Text>
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  <Button onClick={onFriendsOpen}>Add Friends</Button>
                </Box>

                <Box
                  flex={1}
                  bg="rgba(211, 211, 211, 0.5)"
                  p={4}
                  borderRadius="lg"
                >
                  <VStack spacing={4} align="stretch" justifyContent="center">
                    <Text
                      textAlign="center"
                      fontWeight="bold"
                      fontSize="20px"
                      color="white"
                    >
                      Your Active Passes
                    </Text>
                    {userData['passes'] &&
                      userData['passes'].map(
                        (pass, index) =>
                          pass['isActive'] &&
                          eventTime && (
                            <HStack
                              key={index}
                              align="center"
                              justifyContent="center"
                              spacing={4}
                            >
                              <Image
                                src={pass['passImage']}
                                w="35%"
                                borderRadius="md"
                              />
                              <VStack align="stretch">
                                <Text
                                  fontWeight="bold"
                                  fontSize="lg"
                                  color="white"
                                  textAlign="center"
                                >
                                  {pass['passName']} on {eventTime[index]}
                                </Text>

                                <Table variant="simple" size="sm" w="100%">
                                  <Thead>
                                    <Tr>
                                      <Th color="white">Code</Th>
                                      <Th color="white">Guest</Th>
                                      <Th color="white">Status</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    <Tr>
                                      <Td color="white">My Ticket</Td>
                                      <Td color="white"></Td>
                                      <Td>
                                        <Badge bg="green.500" color="white">
                                          Redeemed
                                        </Badge>
                                      </Td>
                                    </Tr>
                                    {pass['codes'] &&
                                      pass['codes'].length > 0 &&
                                      pass['codes'].map((code, idx) => {
                                        const status = codeStatus.get(code);
                                        let badgeColor = 'gray';
                                        if (status === 'Redeemed') {
                                          badgeColor = 'green.500'; // Intense green color
                                        } else if (status === 'Not Redeemed') {
                                          badgeColor = 'red.500'; // Intense red color
                                        } else if (status === 'Error') {
                                          badgeColor = 'orange.500'; // Intense orange color
                                        }
                                        return (
                                          <Tr key={idx}>
                                            <Td color="white">{code}</Td>
                                            <Td color="white">
                                              {status === 'Not Redeemed' ? (
                                                <Button
                                                  size="xs"
                                                  colorScheme="blue"
                                                  onClick={() =>
                                                    handleInviteClick(code)
                                                  }
                                                >
                                                  Invite
                                                </Button>
                                              ) : null}
                                            </Td>
                                            <Td>
                                              <Badge
                                                bg={badgeColor}
                                                color="white"
                                              >
                                                {status}
                                              </Badge>
                                            </Td>
                                          </Tr>
                                        );
                                      })}
                                  </Tbody>
                                </Table>
                              </VStack>
                            </HStack>
                          )
                      )}
                    {userData['passes'] && userData['passes'].length === 0 && (
                      <Text color="white">You have no active passes.</Text>
                    )}
                  </VStack>
                </Box>
              </VStack>
              <Box>
                <Box height={10}></Box>
                <Button onClick={signOutFunction}>Sign Out</Button>
              </Box>
              {/* <Box>
                <Button onClick={testFunction1}>Test APIs</Button>
              </Box> */}

              {/* <Center>
                <div className="App">
                  {loading ? (
                    <div style={{ color: 'white' }}>Loading...</div>
                  ) : (
                    loggedIn && (
                      <div>
                        <h1
                          style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: 40,
                            color: 'white',
                          }}
                        >
                          Welcome back, {userData['firstName']}{' '}
                          {userData['lastName']}!
                        </h1>
                        <div></div>
                        <br />

                        <Center>
                          <br />
                          <br />
                          <br />
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Button variant="solid" onClick={goHome}>
                              Explore Events
                            </Button>
                            <div style={{ width: 30 }}></div>
                            <Button onClick={signOutFunction}>Sign Out</Button>
                          </div>
                        </Center>
                      </div>
                    )
                  )}
                </div>
              </Center> */}
            </div>
          </div>
        )}
        {/* {isTablet && ()} */}
        {(isTablet || isMobile) && (
          <div>
            <div style={{ height: 90 }}></div>
            <div
              style={{
                position: 'absolute',
                top: 50,
                left: 90,
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
              }}
            >
              <a href="/">
                <img
                  style={{ width: 160, height: 50 }}
                  src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                />
              </a>
            </div>
            <Image
              src="/blurred_background.png"
              style={{
                position: 'fixed', // Changed to 'fixed' to cover the whole page
                top: 0,
                left: 0,
                width: '100%',
                minHeight: '100vh', // Ensure it covers at least the viewport height
                objectFit: 'cover',
                zIndex: -1,
              }}
            />
            <div
              id="content-container"
              style={{
                position: 'relative',
                // top: '30px', // Changed to a fixed value from the top
                // paddingBottom: '40px',
                // width: '50%',
                left: '50%',
                transform: 'translateX(-50%)', // Only translate horizontally
                zIndex: 1,
                textAlign: 'center',
                color: '#fff',
              }}
            >
              <Center>
                <div className="App" style={{ width: '90%' }}>
                  {loading ? (
                    <div style={{ color: 'white' }}>Loading...</div>
                  ) : (
                    loggedIn && (
                      <div>
                        <div style={{ height: '20px' }} />
                        <HStack spacing={4} w="full">
                          <Box
                            flex={1}
                            bg="rgba(211, 211, 211, 0.5)"
                            p={4}
                            borderRadius="lg"
                          >
                            <VStack
                              spacing={4}
                              align="stretch"
                              justifyContent="center"
                            >
                              <Text
                                textAlign="center"
                                fontWeight="bold"
                                fontSize="20px"
                                color="white"
                              >
                                Your Active Passes
                              </Text>
                              {userData['passes'] &&
                                userData['passes'].map(
                                  (pass, index) =>
                                    pass['isActive'] &&
                                    eventTime && (
                                      <VStack
                                        key={index}
                                        align="center"
                                        justifyContent="center"
                                        spacing={4}
                                      >
                                        <Image
                                          src={pass['passImage']}
                                          w="65%"
                                          borderRadius="md"
                                        />
                                        <VStack align="stretch">
                                          <Text
                                            fontWeight="bold"
                                            fontSize="lg"
                                            color="white"
                                            textAlign="center"
                                          >
                                            {pass['passName']} on{' '}
                                            {eventTime[index]}
                                          </Text>
                                          <Table variant="simple" size="sm">
                                            <Thead>
                                              <Tr>
                                                <Th color="white">Code</Th>
                                                <Th color="white">Guest</Th>
                                                <Th color="white">Status</Th>
                                              </Tr>
                                            </Thead>
                                            <Tbody>
                                              <Tr>
                                                <Td color="white">My Ticket</Td>
                                                <Td color="white"></Td>
                                                <Td>
                                                  <Badge
                                                    bg="green.500"
                                                    color="white"
                                                  >
                                                    Redeemed
                                                  </Badge>
                                                </Td>
                                              </Tr>
                                              {pass['codes'] &&
                                                pass['codes'].length > 0 &&
                                                pass['codes'].map(
                                                  (code, idx) => {
                                                    const status =
                                                      codeStatus.get(code);
                                                    let badgeColor = 'gray';
                                                    if (status === 'Redeemed') {
                                                      badgeColor = 'green.500'; // Intense green color
                                                    } else if (
                                                      status === 'Not Redeemed'
                                                    ) {
                                                      badgeColor = 'red.500'; // Intense red color
                                                    } else if (
                                                      status === 'Error'
                                                    ) {
                                                      badgeColor = 'orange.500'; // Intense orange color
                                                    }
                                                    return (
                                                      <Tr key={idx}>
                                                        <Td color="white">
                                                          {code}
                                                        </Td>
                                                        <Td color="white">
                                                          {status ===
                                                          'Not Redeemed' ? (
                                                            <Button
                                                              size="xs"
                                                              colorScheme="blue"
                                                              onClick={() =>
                                                                handleInviteClick(
                                                                  code
                                                                )
                                                              }
                                                            >
                                                              Invite
                                                            </Button>
                                                          ) : null}
                                                        </Td>
                                                        <Td>
                                                          <Badge
                                                            bg={badgeColor}
                                                            color="white"
                                                          >
                                                            {status}
                                                          </Badge>
                                                        </Td>
                                                      </Tr>
                                                    );
                                                  }
                                                )}
                                            </Tbody>
                                          </Table>
                                        </VStack>
                                      </VStack>
                                    )
                                )}
                              {userData['passes'] &&
                                userData['passes'].length === 0 && (
                                  <Text color="white">
                                    You have no active passes.
                                  </Text>
                                )}
                            </VStack>
                          </Box>
                        </HStack>
                        <div style={{ height: '20px' }} />
                        <Box
                          bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                          p={4} // padding
                          borderRadius="lg" // rounded corners
                        >
                          Welcome to Wave! With Wave, your ID is your ticket. No
                          resellers and no fake tickets means a safer night out
                          for everyone. <br />
                          Have a code? Redeem it below. <br />
                          <Button variant="solid" onClick={goToRedeem}>
                            Redeem Code
                          </Button>
                        </Box>
                        <div style={{ height: '20px' }} />
                        {auth.currentUser && userData.birthdate && (
                          <Box
                            bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                            p={4} // padding
                            borderRadius="lg" // rounded corners
                          >
                            <FormControl id="first-last-name" mb={4}>
                              <FormLabel color="black">Name</FormLabel>
                              <Box
                                p={2}
                                bg="white"
                                borderRadius="md"
                                color="black"
                              >
                                {userData['firstName']} {userData['lastName']}
                              </Box>
                            </FormControl>

                            <FormControl id="email" mb={4}>
                              <FormLabel color="black">Email</FormLabel>
                              <Box
                                p={2}
                                bg="white"
                                borderRadius="md"
                                color="black"
                              >
                                {userData['email']}
                              </Box>
                            </FormControl>

                            <FormControl id="birthdate" mb={4}>
                              <FormLabel color="black">Birthdate</FormLabel>
                              <Box
                                p={2}
                                bg="white"
                                borderRadius="md"
                                color="black"
                              >
                                {convertBirthdate(userData['birthdate'])}
                              </Box>
                            </FormControl>

                            <FormControl id="phone-number">
                              <FormLabel color="black">Phone Number</FormLabel>
                              <Box
                                p={2}
                                bg="white"
                                borderRadius="md"
                                color="black"
                              >
                                {formatPhoneNumber(
                                  auth.currentUser.phoneNumber
                                )}
                              </Box>
                            </FormControl>
                          </Box>
                        )}
                        <div style={{ height: '20px' }} />

                        <Box
                          bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                          p={4} // padding
                          borderRadius="lg" // rounded corners
                          w="100%"
                        >
                          My Friends <br />
                          {/* make an accordion with sections for friends list, incoming friend requests, sent friend requests */}
                          <Accordion allowToggle>
                            {userData['friends'] && (
                              <AccordionItem>
                                <AccordionButton>
                                  Friends List <AccordionIcon />{' '}
                                </AccordionButton>
                                <AccordionPanel>
                                  {userData['friends'].map(friend => (
                                    <Box key={friend}>
                                      {names[friend] || 'Loading...'}
                                    </Box>
                                  ))}
                                  {userData['friends'].length === 0 && (
                                    <Text>No friends added yet!</Text>
                                  )}
                                </AccordionPanel>
                              </AccordionItem>
                            )}

                            <AccordionItem>
                              <AccordionButton>
                                Incoming Requests <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel>
                                {recvdReqs.map(request => (
                                  <Box key={request.sender}>
                                    {names[request.sender] || 'Loading...'}{' '}
                                    <Button
                                      onClick={() =>
                                        friendAction(request, true)
                                      }
                                      size="xs"
                                    >
                                      Approve
                                    </Button>{' '}
                                    <Button
                                      onClick={() =>
                                        friendAction(request, false)
                                      }
                                      size="xs"
                                    >
                                      Deny
                                    </Button>
                                  </Box>
                                ))}
                                {recvdReqs.length === 0 && (
                                  <Text>No requests received yet!</Text>
                                )}
                              </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionButton>
                                Sent Requests <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel>
                                {sentRequests.map(request => (
                                  <Box key={request.receiver}>
                                    {names[request.receiver] || 'Loading...'}{' '}
                                    {/* <Button
                                onClick={() => friendAction(request, false)}
                                size="xs"
                              >
                                Delete
                              </Button> */}
                                  </Box>
                                ))}
                                {sentRequests.length === 0 && (
                                  <Text>No requests sent yet!</Text>
                                )}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                          <Button onClick={onFriendsOpen}>Add Friends</Button>
                        </Box>
                        <div style={{ height: '20px' }} />
                        <Box
                          bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                          p={4} // padding
                          borderRadius="lg" // rounded corners
                        >
                          The best live shows, parties, and VIP experiences -
                          all on one app. Catch the Wave today. Coming soon to
                          iOS and Android. <br />
                          {/* <HStack> */}
                          <Button
                            variant="solid"
                            // leftIcon={<FaApple />}
                            onClick={() => (window.location.href = '/')}
                            isDisabled
                          >
                            Coming Soon
                          </Button>
                        </Box>
                        <Box>
                          <Box height={10}></Box>
                          <Button onClick={signOutFunction}>Sign Out</Button>
                          <Box height={5}></Box>
                        </Box>
                      </div>
                    )
                  )}
                </div>
              </Center>
            </div>
          </div>
        )}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invite Guest</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Choose one of your friends to send this ticket to. This friend
              must already have a Wave account, and must be your friend already.
              <br />
              <br />
              {ticketTransferLoading && 'Loading...'}
              {!ticketTransferLoading && (
                <Box>
                  {userData['friends'] &&
                    userData['friends'].map(friend => (
                      <Box key={friend}>
                        {names[friend] || 'Loading...'}{' '}
                        <Button
                          onClick={() => sendTicket(friend, selectedCode)}
                          size="xs"
                        >
                          Send
                        </Button>
                      </Box>
                    ))}
                </Box>
              )}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isFriendsOpen} onClose={onFriendsClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Request your Friends</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={isInvalid}>
                <FormLabel htmlFor="sms-number">SMS Number</FormLabel>
                <Input
                  id="sms-number"
                  type="tel"
                  value={smsNumber}
                  onChange={handleSmsNumberChange}
                />
                {isInvalid && (
                  <FormErrorMessage>Invalid phone number.</FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleFriends}
                isDisabled={isInvalid}
                isLoading={requestingFriend}
              >
                Send Invite
              </Button>
              <Button onClick={onFriendsClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </ChakraProvider>
  );
}

export default Profile;
