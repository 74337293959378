import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  theme,
  Text,
  IconButton,
  Image,
  Link,
  Button,
  Divider,
  Center,
  Flex,
  HStack,
  VStack,
  Box,
  SimpleGrid,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Select,
  FormLabel,
  DrawerFooter,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Textarea,
} from '@chakra-ui/react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  collection,
  query,
  doc,
  getDoc,
  getDocs,
  where,
  updateDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase-config';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useToast } from '@chakra-ui/react';
import {
  AddressElement,
  Elements,
  PaymentElement,
  ExpressCheckoutElement,
  confirmPayment,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import ButtonTheme from '../components/Button';

import { FaApple, FaBullhorn } from 'react-icons/fa';
import { AiFillAndroid } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive';
const MyPaymentForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const [payButtonLoading, setPayButtonLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  let navigate = useNavigate();
  function goToProfile() {
    navigate('/profile');
  }

  async function codesEmail(email, codes, image) {
    const mailchimp = require('@mailchimp/mailchimp_transactional')(
      process.env.REACT_APP_MANDRILL_KEY
    );
    const message = {
      from_email: 'support@wavenightlife.com',
      from_name: 'Wave Dynamics',
      to: [
        {
          email: email,
          type: 'to',
        },
      ],
    };
    const response = await mailchimp.messages.sendTemplate({
      template_name: 'order-confirmation-stache-bash',
      template_content: [
        { name: 'CURRENT_YEAR', content: new Date().getFullYear() },
        { name: 'COMPANY', content: 'Wave Dynamics, Inc.' },
        {
          name: 'DESCRIPTION',
          content:
            'We send special offers and updates to all customers who opted in.',
        },
        { name: 'ADDRESS', content: '501 Union St, Nashville, TN 37219' },
        { name: 'IMAGE_URL', content: image },
        { name: 'CODES', content: codes },
      ],
      message: message,
    });

    if (response.status !== 'sent') {
      console.log(response, message);
    }
  }

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPayButtonLoading(true);
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://app.wavenightlife.com/success',
      },
    });

    if (error) {
      setPayButtonLoading(false);
      toast({
        title: 'Purchase failed.',
        description:
          'Double check your card number and try again. If the problem persists, contact Wave Support.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      });
    } else {
      const docRef = doc(db, 'Users', auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      let tempPasses = docSnap.data()['passes'];

      const eventsQuery = query(
        collection(db, 'featuredEvents'),
        where('id', '==', props.id)
      );
      const querySnapshot = await getDocs(eventsQuery);
      const eventDocSnap = querySnapshot.docs[0];
      const eventDocData = eventDocSnap.data();
      const prices = eventDocData.prices;
      const priceObj = prices[props.index];

      const newTicketsPurchased = priceObj.ticketsPurchased + props.numTickets;
      prices[props.index].ticketsPurchased = newTicketsPurchased;

      // If the new amount of ticketsPurchased reaches the ticketLimit, set soldOut to true
      if (newTicketsPurchased >= priceObj.ticketLimit) {
        prices[props.index].soldOut = true;
      }

      // update the event with the new ticket numbers
      const eventDocRef = eventDocSnap.ref;
      await updateDoc(eventDocRef, {
        prices: prices, // This contains the modified prices array
      });

      var tempPassDetails = props.passDetails;
      // tempPassDetails['numTickets'] = props.numTickets;
      // console.log(props.passDetails);

      if (props.numTickets > 1) {
        var codes = '';
        var promises = [];
        props.openDrawerFunction();

        for (let i = 0; i < props.numTickets - 1; i++) {
          const promise = fetch(
            'https://us-central1-wave-dynamics.cloudfunctions.net/adminapi/generateCode',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                authorization: '53266ec5-a432-4b2b-b0dd-30efa4f0a91a',
              },
              body: JSON.stringify({
                pass: props.passDetails,
              }),
            }
          )
            .then(response => response.json())
            .then(code => {
              return code.code; // Return the code for aggregation in the next .then
            })
            .catch(error => {
              console.error('Error:', error);
            });

          promises.push(promise);
        }

        Promise.all(promises)
          .then(async codesArray => {
            // Join all the codes with a new line in between
            codes = codesArray.join('\n');
            props.setCodes(codes);
            props.setCodeLoading(false);
            codesEmail(
              auth.currentUser.email,
              codes,
              `<img width="540" height="auto" style="border:0;width:540px;height:auto;max-width:540px !important;display:block" alt="" src="${props.passDetails.eventFlyerPicture}" role="presentation" class="imageDropZone mceImage"/>`
            );
            tempPassDetails['codes'] = [];
            for (var i = 0; i < codesArray.length; i++) {
              tempPassDetails['codes'].push(codesArray[i]);
            }
            // console.log(props.numTickets);
            tempPassDetails['numTickets'] = props.numTickets;
            tempPasses.push(tempPassDetails);
            await updateDoc(docRef, {
              passes: tempPasses,
              updateRequired: true,
            });
            props.closeFunction();
            setPayButtonLoading(false);
          })
          .catch(error => {
            console.error('Error in promises:', error);
          });
      } else {
        tempPassDetails['numTickets'] = props.numTickets;
        tempPasses.push(tempPassDetails);
        await updateDoc(docRef, {
          passes: tempPasses,
          updateRequired: true,
        });
      }
      

      toast({
        title: 'Purchase was successful!',
        description: 'Download the app to view your passes and tickets.',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'bottom-right',
      });
      // add a toast here to say "payment is successful"
      props.closeFunction();
      setPayButtonLoading(false);
      // here - redirect to profile page after 1 second
      // const timer = setTimeout(() => {
      //   // Perform the action you want after the sleep here
      //   goToProfile();
      // }, 5000);

    }
  };
  return (
    <DrawerBody>
      <form>
        <Stack spacing="24px">
          <Box>
            {/* <ExpressCheckoutElement/> */}
            <AddressElement options={{ mode: 'billing' }} />
            <PaymentElement />
          </Box>
          <DrawerFooter
            borderTopWidth="1px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <VStack>
              <Text>
                Pass Price: $
                {(Number(props.origPrice / 100) * props.numTickets).toFixed(2)}
              </Text>
              <Text>
                Service Fee: $
                {(
                  Number(props.origPrice / 100) *
                  props.numTickets *
                  0.15
                ).toFixed(2)}
              </Text>
              <Text>
                Local Sales Tax: $
                {(
                  Number(props.origPrice / 100) *
                  props.numTickets *
                  1.15 *
                  props.taxRate
                ).toFixed(2)}
              </Text>
              <Button
                colorScheme="blue"
                disabled={!stripe}
                onClick={handleSubmit}
                style={{ width: '100%' }}
                isLoading={payButtonLoading}
                loadingText="Processing"
              >
                Pay ${Number(props.passPrice / 100).toFixed(2)}
              </Button>
            </VStack>
          </DrawerFooter>
        </Stack>
      </form>
    </DrawerBody>
  );
};
export { MyPaymentForm };
