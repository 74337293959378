import React, { useEffect, useState } from 'react';
import { ChakraProvider, theme, Link } from '@chakra-ui/react';
import { Carousel } from 'react-carousel-minimal';
import { collection, query, getDocs } from 'firebase/firestore';
import db from '../firebase-config';

function NotFound() {
  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <div style={{ textAlign: 'center' }}>Error 404: Page Not Found</div>
      </div>
    </ChakraProvider>
  );
}

export default NotFound;
