import React, { useEffect } from 'react';
import { ChakraProvider, theme, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { auth } from '../firebase-config';
import { useLocation } from 'react-router-dom';

function Authflow() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageName = queryParams.get('redirect');
  const navigate = useNavigate();
  function goHome() {
    if (pageName == null) {
      navigate('/');
    } else if (pageName === "redeem") {
      navigate('/redeem');
    } else {
      navigate(`/events/${pageName}`);
    }
  }
  function missingProfile() {
    if (pageName == null) {
      navigate('/createuser');
    } else {
      navigate(`/createuser?redirect=${pageName}`);
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user == null) {
        navigate('/');
      } else if (user.email == null) {
        missingProfile();
      } else {
        // User is signed out
        goHome();
        
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <div>Loading...</div>;
}

export default Authflow;
