import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  theme,
  Link,
  Input,
  HStack,
  FormControl,
  FormLabel,
  Button,
  Center,
  useToast,
  Image,
  RadioGroup,
  Radio,
  Box,
  Checkbox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { auth, db } from '../firebase-config';
import { updateEmail } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive';
import { useLocation } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
import '../styles/styles.css';

function CreateUser() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageName = queryParams.get('redirect');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const {
    isOpen: isSecondDialogOpen,
    onOpen: onSecondDialogOpen,
    onClose: onSecondDialogClose,
  } = useDisclosure();
  const [loggedIn, setLoggedIn] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [agreedEmails, setAgreedEmails] = useState(false);
  const [userData, setUserData] = useState(new Map());
  const [startDate, setStartDate] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFirst, setUserFirst] = useState('');
  const [prefFirst, setPrefFirst] = useState('');
  const [instaName, setInstaName] = useState('');
  const [userLast, setUserLast] = useState('');
  const [gender, setGender] = useState('male');
  const [loading, setLoading] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const toast = useToast();

  const navigate = useNavigate();
  function goHome() {
    navigate('/');
  }
  function goToLogin() {
    navigate(`/login`);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user == null) {
        // no user means go home
        goHome();
      } else {
        setLoggedIn(true);
        const userUID = auth.currentUser.uid;
        if (userUID !== null) {
          const snap = await getDoc(doc(db, 'Users', userUID));
          setUserData(snap.data());
          if (
            snap.data()['birthdate'] !== '' &&
            snap.data()['firstName'] !== '' &&
            snap.data()['lastName'] !== ''
          ) {
            goHome();
          }
        }
      }

      document.body.style.background = '#141416';
    });

    return () => {
      unsubscribe();
    };
  }, []);

  async function termsFunction() {
    if (!agreedTerms || !agreedEmails) {
      toast({
        title: 'Error',
        description:
          'You must agree to the terms and conditions and emails to continue.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      });
      return;
    } else {
      onSecondDialogClose();
      const userUID = auth.currentUser.uid;
      const userRef = doc(db, 'Users', userUID);
      await setDoc(userRef, { agreeTerms: true }, { merge: true });
      await setDoc(userRef, { agreeEmails: true }, { merge: true });
      // setLoggedIn(false);
      if (pageName == null) {
        goHome();
      } else {
        navigate(`/events/${pageName}`);
      }
    }
  }

  async function welcomeEmail(email, first) {
    const mailchimp = require('@mailchimp/mailchimp_transactional')(
      process.env.REACT_APP_MANDRILL_KEY
    );
    const message = {
      from_email: 'support@wavenightlife.com',
      from_name: 'Wave Dynamics',
      to: [
        {
          email: email,
          type: 'to',
        },
      ],
    };
    const response = await mailchimp.messages.sendTemplate({
      template_name: 'welcome-to-wave',
      template_content: [
        { name: 'FNAME', content: first },
        { name: 'CURRENT_YEAR', content: new Date().getFullYear() },
        { name: 'COMPANY', content: 'Wave Dynamics, Inc.' },
        {
          name: 'DESCRIPTION',
          content:
            'We send special offers and updates to all customers who opted in.',
        },
        { name: 'ADDRESS', content: '501 Union St, Nashville, TN 37219' },
      ],
      message: message,
    });

    if (response.status !== 'sent') {
      console.log(response, message);
    }
  }

  async function addUserData(
    email,
    birthdate,
    first,
    last,
    insta,
    pref,
    gender
  ) {
    onClose();
    try {
      if (email && birthdate && first && last && gender) {
        if (insta.charAt(0) !== '@') {
          insta = '@' + insta;
        }

        // format name by trimming any whitespace and capitalizing first letter of the first and last names
        first = first.trim().charAt(0).toUpperCase() + first.trim().slice(1);
        last = last.trim().charAt(0).toUpperCase() + last.trim().slice(1);

        const userUID = auth.currentUser.uid;
        const snap = await getDoc(doc(db, 'Users', userUID));
        const userTemp = snap.data();
        userTemp['phoneNumber'] = auth.currentUser.phoneNumber;
        userTemp['firstName'] = first;
        userTemp['lastName'] = last;
        userTemp['birthdate'] = birthdate;
        userTemp['email'] = email;
        userTemp['instagram'] = insta;
        userTemp['prefFirst'] = pref;
        userTemp['updateRequired'] = false;
        userTemp['agreeEmails'] = false;
        userTemp['agreeTerms'] = false;
        userTemp['friends'] = [];
        userTemp['gender'] = gender;
        await setDoc(doc(db, 'Users', auth.currentUser.uid), userTemp);
        await updateEmail(auth.currentUser, email);
        welcomeEmail(email, first);
        onSecondDialogOpen();
      } else {
        console.log(email, birthdate, first, last, gender);
        toast({
          title: 'Error: Incomplete Data',
          description: 'Make sure to fill in all fields before submitting!',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    } catch (error) {
      // toast to try reauthenticating using Login button
      console.log(error);
      if (error.code === 'auth/requires-recent-login') {
        toast({
          title: 'Error',
          description: 'Try logging in again with the Login button.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else if (error.code === 'auth/invalid-email') {
        toast({
          title: 'Error',
          description: 'Invalid email. Try using a different email.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else if (error.code === 'auth/email-already-in-use') {
        toast({
          title: 'Error',
          description: 'Email already in use. Try using a different email.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else {
        toast({
          title: 'Unknown Error',
          description: `${error}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    }
  }

  return loggedIn ? (
    <>
      <ChakraProvider theme={theme}>
        {isDesktop && (
          <div
          // style={{
          //   display: 'flex',
          //   flexDirection: 'column',
          //   justifyContent: 'center',
          //   alignItems: 'center',
          //   height: '100vh',
          //   textAlign: 'center',
          //   color: '#fff',
          //   zIndex: 1,
          // }}
          >
            <Image
              src="/blurred_background.png"
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '900px',
                objectFit: 'cover',
                zIndex: -1,
              }}
            />
            <div
              id="content-container"
              style={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '60%',
                zIndex: 1,
                textAlign: 'center',
                color: '#fff',
              }}
            >
              <Box w={350} h={650}>
                <div>
                  <h1
                    style={{ fontWeight: 'bold', fontSize: 30, color: 'white' }}
                  >
                    Add Your Information
                  </h1>
                  <div>
                    With Wave, your ID is your ticket.
                    <br />
                    <br /> Your information below will be matched to your
                    tickets, so make sure it matches your ID exactly before
                    submitting.
                    <br /> <br />
                  </div>
                  <HStack w={350}>
                    <FormControl isRequired>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        placeholder="First name"
                        value={userFirst}
                        onChange={e => setUserFirst(e.target.value)}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        placeholder="Last name"
                        value={userLast}
                        onChange={e => setUserLast(e.target.value)}
                      />
                    </FormControl>
                  </HStack>
                  <br />
                  <HStack w={350}>
                    <FormControl>
                      <FormLabel>Preferred First Name</FormLabel>
                      <Input
                        placeholder="Preferred name"
                        value={prefFirst}
                        onChange={e => setPrefFirst(e.target.value)}
                      />
                    </FormControl>
                  </HStack>
                  <br />
                  <FormControl isRequired>
                    <FormLabel>Gender</FormLabel>
                    <RadioGroup onChange={setGender} value={gender}>
                      <HStack direction="row">
                        <Radio value="male">Male</Radio>
                        <Radio value="female">Female</Radio>
                        <Radio value="other">Other</Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <br />
                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      placeholder="Email address"
                      w={350}
                      value={userEmail}
                      onChange={e => setUserEmail(e.target.value)}
                    />
                  </FormControl>
                  <br />
                  <FormControl isRequired>
                    <FormLabel>Birthdate</FormLabel>
                    <Input
                      type="date"
                      placeholder="01/25/1999"
                      selected={startDate}
                      onChange={e => setStartDate(e.target.value)}
                      maxLength={10}
                    />
                  </FormControl>
                  <br />
                  <HStack w={350}>
                    <FormControl>
                      <FormLabel>Instagram Handle</FormLabel>
                      <Input
                        placeholder="@wave_nightlife"
                        value={instaName}
                        onChange={e => setInstaName(e.target.value)}
                      />
                    </FormControl>
                  </HStack>
                  <br />
                  <Button colorScheme="blue" onClick={onOpen}>
                    Submit
                  </Button>
                  <br />
                  <br />
                  <Button onClick={goToLogin}>Login</Button>
                </div>
              </Box>
            </div>
          </div>
        )}
        {/* {isTablet && ()} */}
        {(isMobile || isTablet) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh', // Use minHeight to allow the container to grow
              textAlign: 'center',
              color: '#fff',
              zIndex: 1,
              padding: '20px', // Add some padding to ensure content doesn't touch the edges
            }}
          >
            <Image
              src="/blurred_background.png"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                backgroundColor: 'black',
                zIndex: -1,
              }}
            />
            <div
              id="content-container"
              style={{
                position: 'relative',
                zIndex: 1,
                textAlign: 'center',
                color: '#fff',
                paddingTop: '20%',
                paddingBottom: '60%', // Add padding to the bottom to ensure content doesn't overlap
                width: '90%',
              }}
            >
              <Box w="100%" h={650}>
                <div>
                  <h1
                    style={{ fontWeight: 'bold', fontSize: 30, color: 'white' }}
                  >
                    Add Your Information
                  </h1>
                  <div>
                    With Wave, your ID is your ticket.
                    <br />
                    <br /> Your information below will be matched to your
                    tickets, so make sure it matches your ID exactly before
                    submitting.
                    <br /> <br />
                    If you have any issues, try and log in again with the button
                    below.
                    <br />
                    <br />
                  </div>
                  <HStack w="100%">
                    <FormControl isRequired>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        placeholder="First name"
                        value={userFirst}
                        onChange={e => setUserFirst(e.target.value)}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        placeholder="Last name"
                        value={userLast}
                        onChange={e => setUserLast(e.target.value)}
                      />
                    </FormControl>
                  </HStack>
                  <br />

                  <HStack w="100%">
                    <FormControl>
                      <FormLabel>Preferred First Name</FormLabel>
                      <Input
                        placeholder="Preferred name"
                        value={prefFirst}
                        onChange={e => setPrefFirst(e.target.value)}
                      />
                    </FormControl>
                  </HStack>
                  <br />
                  <FormControl isRequired>
                    <FormLabel>Gender</FormLabel>
                    <RadioGroup onChange={setGender} value={gender}>
                      <HStack direction="row">
                        <Radio value="male">Male</Radio>
                        <Radio value="female">Female</Radio>
                        <Radio value="other">Other</Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <br />
                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      placeholder="Email address"
                      w="100%"
                      value={userEmail}
                      onChange={e => setUserEmail(e.target.value)}
                    />
                  </FormControl>
                  <br />
                  <FormControl isRequired>
                    <FormLabel>Birthdate</FormLabel>
                    <Input
                      // type="birthdate"
                      placeholder="01/25/1999"
                      selected={startDate}
                      onChange={e => setStartDate(e.target.value)}
                      maxLength={10}
                      type="date"
                    />
                  </FormControl>
                  <br />
                  <HStack w="100%">
                    <FormControl>
                      <FormLabel>Instagram Handle</FormLabel>
                      <Input
                        placeholder="@wave_nightlife"
                        value={instaName}
                        onChange={e => setInstaName(e.target.value)}
                      />
                    </FormControl>
                  </HStack>
                  <br />
                  <Button colorScheme="blue" onClick={onOpen}>
                    Submit
                  </Button>
                  <br />
                  <br />
                  <Button onClick={goToLogin}>Login</Button>
                </div>
              </Box>
            </div>
          </div>
        )}
      </ChakraProvider>
      <ChakraProvider>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isCentered={true}
          style={{ zIndex: 1000 }}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirm Information
              </AlertDialogHeader>

              <AlertDialogBody>
                Legal name: {userFirst} {userLast}. Does this name match your
                government ID exactly? Your ID is your ticket - any mistakes
                will make your ticket invalid.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  No, Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() =>
                    addUserData(
                      userEmail,
                      startDate,
                      userFirst,
                      userLast,
                      instaName,
                      prefFirst,
                      gender
                    )
                  }
                  ml={3}
                >
                  Yes, Submit
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <AlertDialog
          isOpen={isSecondDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={onSecondDialogClose}
          isCentered={true}
          style={{ zIndex: 1000 }} // Adjust zIndex to make it appear on top of the first dialog
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Accept Terms and Conditions
              </AlertDialogHeader>

              <AlertDialogBody>
                <Checkbox
                  defaultChecked
                  isChecked={agreedTerms}
                  onChange={e => setAgreedTerms(e.target.checked)}
                >
                  By clicking Finish below, I consent to Wave's{' '}
                  <a
                    href="https://wavenightlife.com/terms-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                    }}
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://wavenightlife.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                    }}
                  >
                    Privacy Policy
                  </a>
                  .
                </Checkbox>
                <div style={{ height: 10 }} />
                <Checkbox
                  defaultChecked
                  isChecked={agreedEmails}
                  onChange={e => setAgreedEmails(e.target.checked)}
                >
                  By clicking Finish below, I agree to receive special offers
                  and messages from Wave. You can opt out later at any time.
                </Checkbox>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onSecondDialogClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => termsFunction()}
                  ml={3}
                >
                  Finish
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </ChakraProvider>
    </>
  ) : (
    <div style={{}}>Loading...</div>
  );
}

export default CreateUser;
